import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import { GoOrganization } from "react-icons/go";
import { IoMdContact } from "react-icons/io";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";
import { getVisitDetails } from "../../api/get/visitDetails";
import FileBadge from "./FileBadge";

const DashboardStats = ({ data }) => {
    console.log("visitData------",data);
    const stats = [
        {
            bgColor: 'bg-green-400',
            icon: <FaCalendarAlt color="white" size={30} />,
            label: 'Date',
            value: new Date(data.visitDateTime).toDateString(),
        },
        {
            bgColor: 'bg-blue-400',
            label: 'Time',
            value: new Date(data.visitDateTime).toLocaleTimeString(),
            icon: <IoMdTime color="white" size={30} />,
        },
        {
            bgColor: 'bg-indigo-400',
            label: 'Institution',
            value: data.organisationId.organizationName,
            icon: <GoOrganization color="white" size={30} />,
        },
        {
            bgColor: 'bg-red-400',
            label: 'Contact Person',
            value: data.contactPersonId.name,
            icon: <IoMdContact color="white" size={30} />,
        },
        {
            bgColor: 'bg-purple-600',
            label: 'Visited Person',
            value: data.employeeId.userId.fullName,
            icon: <BsFillPersonVcardFill color="white" size={30} />,
        }
        , {
            bgColor: 'bg-yellow-600',
            label: 'Uploaded Files',
            value: `${data.file == undefined ?'0': data.file.length} files Uploaded`,
            icon: <FaFileAlt color="white" size={30} />,
        }
    ];

    return (
        <div className="grid grid-cols-1 gap-4 px-4 mt-8 sm:grid-cols-1 md:grid-cols-2 sm:px-8">
            {stats.map((stat, index) => (
                <div key={index} className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
                    <div className={`p-4 ${stat.bgColor}`}>
                        {stat.icon}
                    </div>
                    <div className="px-4 text-gray-700">
                        <h3 className="text-sm tracking-wider">{stat.label}</h3>
                        <p className="text-1xl font-medium">{stat.value}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

// map the files of visit data
const VistDataFiles =({file})=>{
    return(
    <div className="w-11/12 overflow-hidden flex flex-col sm:flex-row items-center sm:items-start justify-center flex-wrap ">
        {
       file && file.map((item,index)=>(
                    <FileBadge
                    name={item.originalname}
                    filename={item.filename}
                    originalname={item.originalname}
                    handleRemove={() => {
                        console.log("remove")
                    }}
                    hideClose={true}
                    path={item.path}
                />
                
            ))
        }
    </div>
    )
}

export default function ViewVisit({ showModal, setShowModal, viewId }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getData() {
            const response = await getVisitDetails(viewId);
            console.log("visit Details", response);
            setData(response.data);
        }
        getData();
    }, [viewId]);

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Visit Details 
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/* body */}
                                <div className="relative p-6 flex-auto">
                                    <input value={viewId} hidden={true} />
                                    <h3 className="text-2xl">
                                        Visit Purpose 
                                    </h3>
                                    {/* Render DashboardStats component with the visit data */}
                                    {data && <DashboardStats data={data.visit} />}
                                    {/* Render Follow Up Actions if they exist */}
                                    {data && data.followUpActions && data.followUpActions.length > 0 && (
                                        <div className='w-full px-5 py-8 mx-auto bg-white rounded-lg shadow-xl'>
                                            <div className='mx-auto space-y-6'>
                                                <div className="container mx-auto p-4">
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Details
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Due Date
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Status
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {data.followUpActions.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="whitespace-nowrap">
                                                                            <div className="flex items-center">
                                                                                <div className="ml-4">
                                                                                    <div className="text-sm font-medium text-gray-900">{item.actionDetails}</div>
                                                                                    <div className="text-sm text-gray-500">{item.actionDescription}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm text-gray-900">{new Date(item.actionDueDate).toDateString()}</div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            {
                                                                                item.actionStatus === 0 ? (
                                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-yellow-800">
                                                                                        Pending
                                                                                    </span>
                                                                                ) : item.actionStatus === 1 ? (
                                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                                        Completed
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                                        Action Needed
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                    {data && <VistDataFiles file={data.visit.file} />}
                                {/* footer */}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}
