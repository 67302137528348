// import React from 'react'

// const CustomSelectList = ({ items, name, onChange ,value ,refv }) => {
//   return (
//     <form class="w-full">
//       {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
//       <select ref={refv} onChange={onChange} name={name} class="bg-gray-50  border border-gray-300 text-gray-900 text-md rounded-lg  w-full p-2.5 dark:bg-[white] dark:border-gray-300 dark:text-gray-600 dark:focus:ring-gray-500 dark:focus:border-gray-500 ">
//         <option >Select..</option>
//         {items.map((item, index) => (
//           <option value={Object.keys(item)[0]} key={Object.keys(item)}    selected={value === Object.keys(item)[0]}>{Object.values(item)[0]}</option>
//         ))}

//       </select>
//     </form>

//   )
// }

// export default CustomSelectList


import React, { useEffect } from 'react';
import Select from 'react-select';

const CustomSelectList = ({ items, name, onChange, value, refv ,disabled, invalidMessage,onFocus}) => {
    const options = items.map((item, index) => ({
        value: Object.keys(item)[0],
        label: Object.values(item)[0]
    }));

    const handleChange = (selectedOption) => {
        onChange({target:selectedOption}); // Assuming onChange expects the value directly
    };


    return (
        <div className="w-full">
            <Select
                name={name}
                ref={refv}
                options={options}
                onChange={(e)=>{if(e!==null){console.log("iuiuhiuhiuh",e.value , name);handleChange({name , value :e.value})}}}
                value={options.find(option => option.value === value)}
                placeholder="Select..."
                className="basic-single select-op"
                // classNamePrefix="select"
                onFocus={onFocus}
                isDisabled={disabled}
                selectedOption={value}
            />
            {invalidMessage&&(<span className="text-red-500 px-4   text-sm">{invalidMessage}</span>)}
        </div>
    );
};

export default CustomSelectList;

