import React from 'react'
import Sidebar from '../components/mainComponents/Sidebar'

const Dashboard = () => {
  return (
    <div className='flex flex-row  min-w-scree'>
        <Sidebar/>
        <div className='flex min-h-screen p-7'>
                <h3>Dashboard</h3>
        </div>
      
    </div>
  )
}

export default Dashboard
