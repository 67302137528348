import axios from 'axios';
import { urlLocal } from '../../url/Url';

const url = urlLocal();


// check the password
export const passwordVerification =async(password)=>{
    try{
        const checkPassword = await axios.post(`${url}/api/authenticate/passwordverify`,{
            "password":password  
        },{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return checkPassword;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

// update the password
export const updateThePassword =async(existing,password)=>{
    try{
        const reset = await axios.post(`${url}/api/authenticate/updatepassword`,{
            existingPassword:existing,
            password:password
        },{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return reset;

    }
    catch(error){
        console.error(error);
        return error;
    }
}

// password reset link triggering function
export const passwordResetLinkTrigger =async(email)=>{
    try{
        const passwordLink = await axios.post(`${url}/api/authenticate/passwordreset`,{
            "email":email.email
        });
        return passwordLink;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

// forgotpassword
export const resetPasswordById = async(encryptPassword , password,date)=>{
    try{
        const reset = await axios.post(`${url}/api/authenticate/changepassword`,{
            "encryptUserId":encryptPassword,
            "password":password,
            "expDate":date
        })
        return reset;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

// get the user emails
export const userEmailFetch =async(encryptid,date)=>{
    try{
        const userEmail = axios.post(`${url}/api/authenticate/getuserData`,{
            "encryptUserId":encryptid,
            "dateTime":date
        })
        return userEmail;
    }
    catch(error){
        console.error(error);
        return error;
    }
}