import { useEffect, useState } from "react";
import { getNotifications } from "../../api/get/notification";




function Notification({ children }) {

    const [open, setOpen] = useState(false);


    function toggleOpen() {
        setOpen(!open);
    }

    function close() {
        setOpen(false);
    }



    const [notifications, setNotifications] = useState([]);


    useEffect(() => {
        async function loader() {
            const response = await getNotifications();
            setNotifications(response);
            console.log("notifications", response)
        }
        loader();

    }, [])


    return (

        <div className="w-full">
            <div className="relative ">
                <div onClick={toggleOpen}>
                    {children}
                </div>
                {notifications.length !== 0 && (
                    <>
                        <div hidden={!open} className="absolute left-0 z-10 mt-2 w-[300px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="py-1" role="none">

                                <div className="w-full max-w-sm mx-auto my-2 overflow-hidden rounded shadow-sm">
                                    <div className="relative flex items-center justify-between px-2 py-2 font-bold text-white bg-blue-500">
                                        <div className="relative flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                className="inline w-6 h-6 mr-2 opacity-75">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                            </svg>
                                            <span>Notification</span>
                                        </div>
                                        <div className="relative" onClick={close} >
                                            <svg className="w-5 h-5 text-blue-300 fill-current hover:text-white" role="button"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <title>Close</title>
                                                <path
                                                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="max-h-52 overflow-y-auto">
                                        {notifications.map((item) => (
                                            <>
                                                <div className="p-3 bg-white border border-gray-300 rounded-b">
                                                    <div className="flex justify-between items-stretch  mb-3 ">
                                                        <div class="relative inline-flex">
                                                            <img src="https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&faces=1&faceindex=1&facepad=2.5&w=500&h=500&q=80"
                                                                className="inline object-cover w-12 h-12 mr-2 rounded-full" />
                                                            <span
                                                                class="absolute rounded-full py-1 px-1 text-xs font-medium content-[''] leading-none grid place-items-center top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4 bg-blue-500 text-white min-w-[24px] min-h-[24px]">
                                                                new
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <p className="font-medium leading-tight text-gray-700 ml-2">{item.from.fullName}</p>
                                                            <span className="block leading-tight text-gray-500 ml-2">{item.message}</span>
                                                        </div>
                                                        <div>
                                                            {item.isOpened === false &&(
                                                                <svg className="w-5 h-5 text-blue-300 fill-current hover:text-white" role="button"
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <title>Close</title>
                                                                <path
                                                                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                                            </svg>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr />
                                            </>
                                        ))}








                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>




        </div>

    )
}

export default Notification;
