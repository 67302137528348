import React, { useState, useEffect, useRef } from 'react';

import SearchComponent from './nav/SearchComponent';
import { getUser } from '../api/get/user';
import { useNavigate } from 'react-router-dom';
import { urlLocal  } from '../url/Url';

const Navbar = ({title}) => {

  const url = urlLocal();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  // Function to handle click outside the dropdown

  const handleClickOutside = (event) => {

    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

      setDropdownOpen(false);

    }

  };

  useEffect(() => {

    // Add event listener to handle clicks outside the dropdown

    document.addEventListener('mousedown', handleClickOutside);

    return () => {

      // Clean up the event listener when the component is unmounted

      document.removeEventListener('mousedown', handleClickOutside);

    };

  }, []);


  const [fullName, setFullName ] = useState("");
  const [email , seEmail ]=  useState("");
  const [profile , setProfile] =  useState(undefined);
  useEffect(()=>{
    async function getUserApi(){
      const response = await getUser();
       seEmail(response.data.email);
      setFullName(response.data.fullName);
      setProfile(response.data.employeeObject.userId.profilePicture || undefined)
      console.log("pro",response)
    }
    getUserApi();
  }, [])

  const navigateToProfile=()=>{
    navigate('/profile');
  }

// signout
const handleSignOut=()=>{
  localStorage.removeItem('token');
  navigate('/');
}

  return (

    <header className="sm:pl-[240px] bg-white shadow p-2 flex justify-between gap-8 items-center fixed inset-x-0 top-0 z-10">

      <div className="flex flex-row text-lg gap-2 font-semibold">

        <div className='ml-[50px] sm:ml-3 xl:ml-10'>

            <h3 className='text-md sm:text-2xl'>{title}</h3>

        </div>

        {/* <div className='  ml-1 xl:ml-10 2xl:w-[500px]'>

            {/* <SearchComponent /> */}

        

      </div>
      
      <div className="flex flex-col lg:flex-row justify-center gap-1 xl:gap-2 items-end sm:items-center space-x-4 xl:mr-1">

       

        <div className="relative" ref={dropdownRef}>
          <div  onClick={() => setDropdownOpen(!dropdownOpen)} className='cursor-pointer h-[40px] w-[40px] sm:w-[50px] sm:h-[50px] rounded-full overflow-hidden'>
            <img src={profile ? url+"/"+profile : require('../assets/Profile Picture.svg').default} className='w-full h-full ' alt='' />
          </div>

          {dropdownOpen && (

            <div className="absolute right-0 w-48 bg-white shadow-md rounded mt-2 py-2">

              <div onClick={navigateToProfile} className="flex cursor-pointer gap-2 px-4 py-2 text-gray-800 hover:bg-gray-200">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="8" r="4" stroke="black" stroke-width="2" fill="none"/>
                  <path d="M4 22c0-4.418 3.582-8 8-8s8 3.582 8 8" stroke="black" stroke-width="2" fill="none"/>
                </svg>
                Profile
              </div>

              <div onClick={handleSignOut} className="flex cursor-pointer gap-2 px-4 py-2 text-gray-800 hover:bg-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-logout">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                    <path d="M9 12h12l-3 -3" />
                    <path d="M18 15l3 -3" />
                  </svg>
                Logout
              </div>

            </div>

          )}

        </div>

        {/* <div onClick={navigateToProfile} className='cursor-pointer h-[40px] w-[40px] sm:w-[50px] sm:h-[50px] rounded-full overflow-hidden'>

          <img src={profile ? url+"/"+profile : require('../assets/Profile Picture.svg').default} className='w-full h-full ' alt='' />

        </div> */}

        <div className='hidden sm:flex flex-col items-center '>

          <p className='text-[9px] sm:text-[14px]  xl:text-[18px] whitespace-nowrap font-[500]'>{fullName}</p>

          {/* <p className='text-[8px] sm:text-[10px] xl:text-[14px] font-light'>{email}</p> */}
          

        </div>
</div>
     

      {/* {dropdownOpen && (


            <div className="absolute right-0 w-48 top-3 bg-white shadow-md rounded mt-10 py-1">

            <div className="absolute sm:hidden right-0 w-48 bg-white shadow-md rounded mt-10 py-2">


              <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">{fullName}</a>

              <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">{email}</a>
              <a href="/" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">Logout</a>

            </div>

          )}</div>
====
          )} */}


    </header>
    );
};

export default Navbar;
