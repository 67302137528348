import React from 'react';
import Navbar from '../components/navbar';
import Homepage from '../components/Homepage';
import Sidebar from '../components/sidebar';




const Layout = () => {
    return (
        <div >
            <Sidebar/>
                <Navbar  title={'Dashboard'}/>
                <div className="p-4">
                    <Homepage />
                </div>
           
        </div>
    );
}

export default Layout;
