import axios from 'axios';
import { urlLocal } from '../../url/Url';

const url =urlLocal();

export const fetchAppointmentCounts = async () => {
  try {
    const response = await axios.get(`${url}/api/associates/count-Appoinments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching appointment counts:', error);
    throw error;
  }
};

export const fetchVisitCounts = async () => {
    try {
      const response = await axios.get(`${url}/api/associates/visit/count`, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching visit counts:', error);
      throw error;
    }
  };