import React from 'react'

const TextInputNormal = ({name,placeholder,onChange}) => {
  return (
    <div class="py-2">
    <input placeholder={placeholder}
      name={name}
      type="text"
      onChange={onChange}
      class="text-md block px-3 py-2  rounded-lg w-full 
             bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-[#32B1DC] focus:outline-none   h-14"/>
  </div>
  )
}

export default TextInputNormal
