import axios from 'axios';
import { urlLocal } from '../../url/Url';

const url = urlLocal();

export const userLogin = async (email, password) => {
  try {
    const userauth = await axios.post(`${url}/api/associates/login`, {
        email: email,
      password: password
    });

    // console.log(userauth);
    return userauth;
  } catch (error) {
    console.error(error);
    // Return the error response if available, otherwise return a generic error message
    return error.response ? error.response : { message: 'An error occurred' };
  }
};
