import React, { useState,useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({dataItems}) => {
  const [chartState, setChartState] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false // Hide the toolbar
        },
        zoom: {
          enabled: false // Disable zoom
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                colors: ['#fff'] // Set the text color here
              }
            }
          }
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#2e2b2b'] // Change this color to your desired text color
        }
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: false,
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    },
  });
  useEffect(() => {
    if (dataItems && dataItems.length > 0) {
      const upcomingVisitsData = dataItems.map(item => item.upcomingVisits || 0);
      const completedData = dataItems.map(item => {
        const completed = item.visitStatuses.find(status => status.status === 'Completed');
        return completed ? completed.count : 0;
      });
      const pendingData = dataItems.map(item => {
        const pending = item.visitStatuses.find(status => status.status === 'Pending');
        return pending ? pending.count : 0;
      });
      const actionNeededData = dataItems.map(item => {
        const actionNeeded = item.visitStatuses.find(status => status.status === 'Action Needed');
        return actionNeeded ? actionNeeded.count : 0;
      });

      // Map organizationType to custom categories
      const categories = dataItems.map(item => {
        switch (item.organizationType) {
          case 'Academic Institutions':
            return 'Academics';
          case 'Government Departments':
            return 'Govt';
          case 'Corporates':
            return 'Corporates';
          default:
            return item.organizationType; // Fallback to original if no match
        }
      });

      // Update chartState with new data and categories
      setChartState(prevState => ({
        ...prevState,
        series: [
          { name: 'UpcomingVisit', data: upcomingVisitsData },
          { name: 'Completed', data: completedData },
          { name: 'Pending', data: pendingData },
          { name: 'Action Needed', data: actionNeededData }
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: categories,
          }
        }
      }));
    }
  }, [dataItems]);
  return (
    <ReactApexChart options={chartState.options} series={chartState.series} type="bar" height={140}  width={250} />
  );
};

export default BarChart;
