import { useState ,useEffect} from "react"

const DropDownlist = (Items) => {
    const [dropdownListVisible, setDropdownlistVisible] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [selectedItem  , setSelectedItem] =  useState('Select');

    const controleDropdownVisibility =()=>{
        setDropdownlistVisible(!dropdownListVisible)
    }

    const selectItemFromList =(item)=>{
        setSelectedItem(item);
    }

  const handleMouseOver = () => {
    const id = setTimeout(() => {
        setDropdownlistVisible(true);
    }, 500); // 500 ms delay
    setTimeoutId(id);
  };

  const handleMouseOut = () => {
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
    setDropdownlistVisible(false);
    }, 500); // 500 ms delay
    setTimeoutId(id);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
  }, [timeoutId]);

  return (
<> 
<div className="relative inline-block">
      <button
        id="dropdownDelayButton"
        onClick={controleDropdownVisibility}
        className="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-200"
        type="button"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {selectedItem}
        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
        </svg>
      </button>
      {dropdownListVisible && (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            id="dropdownDelay"
            className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-400"
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDelayButton">
            <li onClick={()=>selectItemFromList('10')}>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white">10</a>
            </li>
            <li onClick={()=>selectItemFromList('11')}>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white">11</a>
            </li>
            <li onClick={()=>selectItemFromList('12')}>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white">12</a>
            </li>
           
          </ul>
    </div>
    )}
</div>
</>
  )
}

export default DropDownlist
