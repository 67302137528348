import { useState } from "react";
import { FaEye, FaTrash, FaEdit } from 'react-icons/fa';
import ContactModal from "./ContactModal";
const InstitutionTable = ({title,content}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    // Calculate the indexes for slicing the data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = content.slice(indexOfFirstItem, indexOfLastItem);

    // Function to handle page changes
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(content.length / itemsPerPage);
    const [openContactModal , setOpenContactModal] =  useState(false)
    const [orgId,setOrgId] =  useState([]);
  return (
<>




<ContactModal open={openContactModal} setOpen={setOpenContactModal} id={orgId}/>
<div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full px-5 bg-gray-50 ">
    <table class="w-full text-sm text-left rtl:text-right text-gray-900">
        <thead class="text-xs uppercase bg-[#F4F5F7] dark:text-gray-900">
        <tr >
           {
            title.map((data,index)=>(
               
                <th scope="col" className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">
                    {data}
                </th>
         
            ))
           }
            </tr>
          
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {
                currentData.map((item,index)=>(
                <tr key={index} className="py-2"  >
                    <td className="px-1 py-4">{item.orderNo}</td>
                    <td className="px-1 py-4">{item.organizationName}</td>
                    <td className="px-1 py-4">{item.email}</td>
                    <td className="px-1 py-4">{item.place}</td>
                    <td className="px-1 py-4">{item.district}</td>
                    <td className="px-1 py-4">{item.state}</td>
                    <td className="px-6 py-4 text-nowrap   ">
                        <button className="px-3 py-1 font-semibold text-[12px] rounded-md text-blue-600 ">View</button>
                    </td>
                    <td className="flex justify-center px-1 py-4 gap-3">
                        {/* <button className="text-blue-700 flex items-center space-x-1">
                            <FaEye />
                        </button> */}
                        <button className="text-blue-700 flex items-center " >
                            <FaEdit onClick={()=>{setOrgId(item._id);setOpenContactModal(!openContactModal)}}/>
                        </button>
                        <button className="text-blue-700 flex items-center ">
                            <FaTrash />
                        </button>
                    </td>
            </tr>
            ))
            }
          
          
        </tbody>
    </table>
</div>
    <nav className="flex px-10 items-center flex-column flex-wrap md:flex-row justify-between pt-4 pl-4" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900">{indexOfFirstItem + 1}-{indexOfLastItem}</span> of <span className="font-semibold text-gray-900">{content.length}</span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight  bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100  dark:bg-gray-100 dark:border-gray-700 dark:text-gray-900 dark:hover:bg-gray-400 dark:hover:text-white"
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                        <li key={pageIndex}>
                            <button
                                onClick={() => handlePageChange(pageIndex + 1)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                                    currentPage === pageIndex + 1
                                        ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-100 dark:text-gray-500"
                                        : "text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-400 dark:hover:text-white"
                                }`}
                            >
                                {pageIndex + 1}
                            </button>
                        </li>
                    ))}
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-900 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-400 dark:hover:text-white"
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </li>
                </ul>
    </nav>
</>
)
}

export default InstitutionTable;
