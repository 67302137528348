import React from 'react'

const Searchbar = () => {
  return (
                        
<div class=" flex items-center border rounded-lg  focus:ring-gray-500 dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 pr-2">   
  
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input class="block w-full p-3 ps-10 text-sm text-gray-900  rounded-lg  focus:ring-black focus:border-black dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 border-r-0 focus:outline-none" placeholder="Search..." required />
       
    </div>
    <div>
        <button class="text-white  end-2.5 bottom-2.5 bg-gray-500 hover:bg-gray-600 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 focus:ring-1 focus:ring-gray-400 ">Search</button>
    </div>
</div>
  )
}

export default Searchbar;
