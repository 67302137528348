import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

export const getUserProfile = async()=>{
    try{
        const user = await axios.post(`${url}/api/associates/employee/get`,{},{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        });
        return user;
    }
    catch(error){
        console.error(error);
        return error;
    }
}