import React, { useEffect } from 'react'
import VisitLogsTable from '../components/visitLog/VisitlogsTable';
import DropDownlist from '../components/common/DropDownlist';
import Searchbar from '../components/visitLog/SearchBar';
import AddVisitModal from '../components/visitLog/AddVisitModal';
import { useState } from 'react';
import AddInstitutionModal from '../components/instLogs/AddInstModal';
import AddContPersonModal from '../components/visitLog/AddContPersonModal';

// import the sidebar and navbar 
import Sidebar from '../components/sidebar';
import Navbar from '../components/navbar';
import { AddVisit } from '../api/insert/Visit';
import { getAllVisitLogsFromDb } from '../api/get/visit';
   

const VisitLogs = () => {
    const title=["Visit ID","Visited By","Institution","Purpose","Date","Time","Type","Contact Person","Status","Action"];
   
    const college = ['College of Engineering Munnar','MIT','CET','CEV'];

    const [isModalOpen, setModalOpen] = useState(false); //variable for open and close the add visit modal
    const [openInstModel,setOpenInstModal] = useState(false); //variable for open and close the add institusion modal
    const [openAddPersonModal , setOpenAddPersonModal] = useState(false); // variable for controle the open  and close state of add contact person modal

    // variable for storing visitlogs
    const [visitData , setVisitdata] = useState([]);

    const handleOpenModal =()=>{
      setModalOpen(true);
    }
  
    const handleCloseModal=()=>{
      setModalOpen(false);
    }
    // open the add istitusion modal 
const handleOpenAddInstModal =()=>{
    setOpenInstModal(true);
}
// close the add institution modal
const handleCloseInstModal =()=>{
    setOpenInstModal(false);
}

// handle open add contact person modal of institute
const handleOpenAddPersonModal =()=>{
    setOpenAddPersonModal(true)
}

// handle close the add contact person modal
const handleCloseAddPersonModal=()=>{
    setOpenAddPersonModal(false);
}

// add new vist data is get from the addVisit modal
const addNewVisitData =async (data)=>{
    console.log("visitlogs",data);
    const newvisit =await AddVisit(data);
    console.log(newvisit);
    if (newvisit.data !==  undefined)
   {if (newvisit.data.success ) setModalOpen(false);}
}

// json dummy data
const state = ['Kerala','Tamilnadu'];
  const district = ['Kannur','kasaragod'];
  const instType = ['Govt','Corporate','Academics'];





  // getting useEffect 
useEffect(()=>{
      fetchAllVisitLogs();
},[])

// get all visitlogs
const fetchAllVisitLogs =async()=>{
    const visitLogs = await getAllVisitLogsFromDb();
    if(visitLogs.data){
        setVisitdata(visitLogs.data);
    }
    else{

    }
}


  return (
    <>
    <Navbar/>
    <Sidebar/>
        <div className='flex sm:ml-[240px] pt-[50px] flex-col items-center justify-center gap-5 w-auto px-5' >
        
                <div className='w-full flex flex-row justify-between gap-3  sm:items-center pt-3 '>
                    <div className='flex flex-col gap-3 lg:flex-row pl-5 pr-5'>
                        <div className='flex gap-3 items-center'>
                            <p className='text-[15px] font-medium'>Show</p>
                            <DropDownlist/>
                        </div>

                        {/* it is not visible in mobile */}
                        <div className='sm:ml-5 hidden sm:flex items-center'>
                            <Searchbar/>
                            
                        </div>
                    </div>
                    <div className='pr-1 sm:pr-3  bg-white flex justify-end'>
                    <button onClick={handleOpenModal} type="button" class="flex gap-1 text-white bg-[#624DE3] hover:bg-[#624DE3] focus:ring-4 focus:ring-[#a79af8] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb- focus:outline-none dark:focus:ring-[#624DE3] h-[40px] w-[80px]">
                        <img src={require('../assets/plusIcon.svg').default} alt='' />
                        Add
                    </button>
                    </div>
                </div>
                {/* only visible in mobile device */}
                <div className='w-full flex sm:hidden p-5'>
                <Searchbar/>
                </div>
                <VisitLogsTable title={title} content={visitData} updateFunction={fetchAllVisitLogs }/>

            <AddVisitModal open={isModalOpen} onClose={handleCloseModal} institutionList={college} openInstModal={handleOpenAddInstModal} openAddPesonModal={handleOpenAddPersonModal} addVisit={addNewVisitData} />
            <AddInstitutionModal open={openInstModel} onClose={handleCloseInstModal} instType={instType} state={state} district={district}/>
            <AddContPersonModal open={openAddPersonModal} onClose={handleCloseAddPersonModal} />
        </div>
    </>
  )
}

export default VisitLogs;
