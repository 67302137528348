import axios from "axios";
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function  getContactOrgId(org){
    try { 
        const response = await axios.post(`${url}/api/admin/contact/person/getByOrg/${org}` , {
        } , {headers :  {
            Authorization :  localStorage.getItem("token")
        }});
        return response ; 
    }
    catch (error){
        console.log(error);
    }
}