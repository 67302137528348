import axios from 'axios';
import { urlLocal } from '../../url/Url';

 const url = urlLocal();
export const userLogin = async(userid,password)=>{
   try{
    const userauth = await axios.post(`${url}/api/authenticate/login`,{
        "username" : userid ,
        "password" : password
    },);
    console.log(userauth)

    return  userauth;

}
catch(error){
    console.error(error)
    return error;
}
}

