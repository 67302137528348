import React, { useState, useRef } from 'react';

const MultiSelectDropdown = ({items ,handleSelect}) => {
  
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const emplyeeSelect = useRef([])

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    console.log("options----",option);
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(opt => opt !== option));
      emplyeeSelect.current = emplyeeSelect.current.filter(id => id!==Object.keys(option)[0])
      handleSelect( emplyeeSelect.current);
    } 
    else {
      setSelectedOptions([...selectedOptions, option]);
      emplyeeSelect.current = [...emplyeeSelect.current,Object.keys(option)[0]];
      handleSelect( emplyeeSelect.current);
    }
  };

  const getButtonLabel = () => {
    if (selectedOptions.length > 0) {
      return selectedOptions.map((item, index) => {
        const value = Object.values(item)[0];
        return (
          <div key={index} className="px-2 text-[10px] py-1 flex flex-row justify-between items-center gap-1 rounded-2xl text-nowrap bg-[#F9E2CB] m-1">
            {value}
              <svg
                onClick={()=>{handleOptionClick(item)}}
                  className="flex-shrink-0 size-3.5 text-gray-600 hover:text-gray-800 active:text-gray-900 active:size-3"
                  xmlns="http://www.w3.org/2000/svg"
                  
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
              >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
          </div>
        );
      });
    }
    return 'Select...';
  };

  // Close dropdown if clicked outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="relative w-64" ref={dropdownRef}>
      <button
        type="button"
        onClick={toggleDropdown}
        className="relative py-1 px-2 pe-9 flex  flex-col w-full max-w-[170px] cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 overflow-hidden"
      >
       {getButtonLabel()}
        <span className="absolute top-1/2 end-3 -translate-y-1/2">
          <svg className="flex-shrink-0 size-3.5 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/>
          </svg>
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto top-full mt-2 shadow-lg">
          {/* <div
            onClick={()=>{handleAllSelect(items)}}
              className={`py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 `}
            >
              Select All
        </div> */}
          {items.map((option,index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              className={`py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-200 rounded-lg focus:outline-none focus:bg-gray-100 ${selectedOptions.includes(option) ? 'bg-gray-300' : ''}`}
            >
              {Object.values(option)[0]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
