import React from 'react'
import Navbar from '../../components/navbar'
import Sidebar from '../../components/sidebar'
import { useNavigate } from 'react-router-dom'
const Page404 = () => {
    const navigate = useNavigate();
    const navigateToHome =()=>{
        navigate('/')
    }
  return (
    <>
    {/* <Navbar/>
    <Sidebar/> */}
    <div className=' mt-[50px] flex w-full items-center px-4 lg:px-20 py-20'>
        <div className=' grid grid-cols-1 gap-3 sm:gap-10 lg:gap-2 lg:grid-cols-2 w-10/12'>
            <div className=' flex justify-start md:justify-center'>
                <img src={require('../../assets/robot-1.png')} alt='' className='h-[100px] sm:h-[150px] md:h-[200px] xl:h-[300px] object-contain' />
            </div>
            <div className=' flex flex-col gap-1 sm:gap-3 justify-center md:items-center lg:items-start '>
                <h2 className='text-[35px] sm:text-[50px] xl:text-[60px] font-bold'>Oops! 404 Error</h2>
                <p className='text-xl'>We could'nt find a page </p>
                <p className='text-xl'>you were looking for</p>
                <button onClick={navigateToHome} type="button" class="text-white flex gap-5 mt-2  bg-[#32B1DC] w-[170px] hover:bg-[#24acd9] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-2.5 text-center items-center me-2  dark:focus:ring-[#32B1DC]">
                        Back To Home
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    </>
    
  )
}

export default Page404
