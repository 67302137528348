import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CustomSelectList from '../common/CustomSelectList';
import TextInput from '../common/TextInput';

import { getOrganization } from '../../api/get/organization';
import { getContactPerson } from '../../api/get/contactPerson';
import { getUser } from '../../api/get/employee';

import { visitUpdate } from '../../api/update/visit';
import CommonAlert from '../CommomAlert';
import ConfirmationMessage from '../messageModal/CofirmationModal';
import FileBadge from '../common/FileBadge';
import { removeFile } from '../../api/remove/file';
import { isAlphabeticWithouSpecialCharacter } from '../../validations/Validation';



const EditVisit = ({ open, onClose, institutionList, openInstModal, openAddPesonModal, addVisit, institution, visitDateTime, purpose, contactPersonId, discussionTopic, additionalNotes, file , setFile, visitId, employeeId, updateData, visitStatus  , isUpComing}) => {


    function pad(num) {
        return num < 10 ? '0' + num : num;
    }

    const formatDateTime = (utcDateTime) => {
        const date = new Date(utcDateTime);
  
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };
    const [visitdata, setVisitData] = useState({
        visitId: visitId,
        institution: institution || '',
        visitDateTime: visitDateTime || '',
        purpose: purpose || '',
        contactPersonId: contactPersonId || '',
        additionalNotes: additionalNotes || '',
        file: file || [],
        visitType: 1,
        employeeId: employeeId || "",
        discussionTopic: discussionTopic || "",
        visitStatus: visitStatus || ""

    })
    const organizationId = useRef('');
    const inputRef = useRef(null);
    const  inputFileRef = useRef(null);

    const handleReset = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };
    useEffect(() => {
console.log("props data -----------", institution,  employeeId, visitStatus);
        setVisitData({
            visitId: visitId,
            institution: institution || '',
            visitDateTime:  formatDateTime(visitDateTime) || '',
            purpose: purpose || '',
            contactPersonId: contactPersonId || '',
            additionalNotes: additionalNotes || '',
            file: file || [],
            visitType: 1,
            employeeId: employeeId || "",
            discussionTopic: discussionTopic || "",
            visitStatus: visitStatus || ""
        })
        organizationId.current=institution;
        if (institution) getContactPersonFunction(institution)
    }, [institution, visitDateTime, purpose, contactPersonId, discussionTopic, additionalNotes, file, visitId, employeeId])

    // function to handle the textfield input
    const handleChangeText = (e) => {
        const { name, value } = e.target;
        setError({...error,[name]:''});
        setVisitData({ ...visitdata, [name]: value });
    }
   
    const contactPersonRef = useRef('');
    // function for handling the selection of the list
    const handleListSelection = (e) => {
        const { name, value } = e.target;
        setError({...error,[name]:''});
       
        if (name === "institution"){
            organizationId.current = value;
             contactPersonRef.current.clearValue();
            getContactPersonFunction(value);
            setVisitData({ ...visitdata, [name]: value,'contactPersonId':''});
        }
        else {
            setVisitData({ ...visitdata, [name]: value });
          }
    }
// check the visit status and if it is completed then open popup message for confirmation
    const handleaddNewVist = async () => {
        if(visitdata.visitStatus === 'Completed'){
            setOpenConfirmationModal(true);
        }
        else{
            updateTheVisit();
        }
    }

    const submisionForCompleted = async () => {
        await updateTheVisit()
        setOpenConfirmationModal(false);
    }
    //update the visit
    const updateTheVisit = async () => {
        const validatePurpose = isAlphabeticWithouSpecialCharacter(visitdata.purpose);
        const validateNote = isAlphabeticWithouSpecialCharacter(visitdata.additionalNotes);
        let tempInvalidMessages = {};
        Object.keys(visitdata).forEach((key)=>{
            if(visitdata[key] === '' || 
                visitdata[key] === undefined ||
                visitdata[key] === null
                ){
                    if(key === "institution"){
                        tempInvalidMessages[key] = "Please select a Institution"; 
                    }else if(key === "employeeId"){
                        tempInvalidMessages[key] = "Please select Employee ";
                    }
                    else if (key === "visitDateTime") {
                        tempInvalidMessages[key] = "Please pick a date";
                        } 
                    else if (key === "purpose") {
                        tempInvalidMessages[key] = "Please enter  purpose of visit";
                        } 
                    else if (key === "contactPersonId") {
                        tempInvalidMessages[key] = "Please select contact person";
                            } 
                    else if (key === "additionalNotes") {
                            tempInvalidMessages[key] =
                                  "Please enter  purpose of Additional Notes";
                              } 
                    else {
                            tempInvalidMessages[key] =
                                "Invalid data please enter in valid format";
                        }
                }
        })
        setError(tempInvalidMessages);
        if(visitdata.institution ==='' || visitdata.employeeId === '' || visitdata.contactPersonId === '' || visitdata.visitDateTime === '' || visitdata.purpose ==='' || visitdata.additionalNotes == ''){

        }
        else if(visitdata.purpose!=='' && validatePurpose === false){
          setError({...error,'purpose':'Special characters are not allowed'})
        }
        else if(visitdata.additionalNotes!=='' && validateNote === false){
            setError({...error,'additionalNotes':'Special characters are not allowed'})
        }
        else{
            const formData = new FormData();
            Object.keys(visitdata).forEach(key=>{
             formData.set(key, visitdata[key]);
            })
            newFiles.forEach((file,index)=>{
             formData.append("newFiles",file)
            })
     
             const newvisit = await visitUpdate(formData);
             console.log({...visitdata,newFiles})
             console.log(newvisit);
             if (newvisit.data !== undefined)
                 if (newvisit.data.success) {
                     setOpenAlert(true)
                     if (updateData !== undefined) await updateData()
                     onClose();
                 }
        }
    }

    const [contactPerson, setContactPerson] = useState([]);
    async function getContactPersonFunction(orgId) {
        try {
            const contactPersonsResponse = [];
            const response = await getContactPerson(orgId);

            if (response.length === 0) {

                contactPersonsResponse.push({ "": "select" });
                setContactPerson([])
            }

            response.forEach((item) => {
                var obj = {}
                obj[item._id] = item.name + "(" + item.designation + ")"
                contactPersonsResponse.push(obj);
                setContactPerson(contactPersonsResponse)
            });
        }
        catch (error) {
            console.log(error)
        }
    }
    const [organizations, setOrganizations] = useState([])
    const [employees, setEmployee] = useState([]);

    useEffect(() => {
        async function getOrg() {
            const response = await getOrganization();
            var orgs = []
            response.data.organizations.forEach((item) => {
                var obj = {}
                obj[item._id] = item.organizationName + " " + item.place
                orgs.push(obj)
            });
            setOrganizations(orgs);
            async function getEmployeesFromApi() {
                const response = await getUser();
                const emp = [];
                response.data.data.forEach((item) => {
                    var obj = [];
                    obj[item._id] = item.userId.fullName;
                    emp.push(obj)
                });
                setEmployee(emp)
            }
            getEmployeesFromApi()

        }
        if (visitdata.institution) getContactPersonFunction(visitdata.institution)
        getOrg()

    }, [])


    const visitDataOptions = isUpComing ? [{ "Pending": "Pending" }, { "Action Needed": "Action Needed" }] : [{ "Pending": "Pending" }, { "Completed": "Completed" }, { "Action Needed": "Action Needed" }] 
    const [openAlert, setOpenAlert] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [error , setError] = useState({
        institution:'',
        employeeId:'',
        visitDateTime:'',
        purpose:'',
        contactPersonId:'',
        visitStatus:'',
        additionalNotes:'',
        discussionTopic:'',
        file:'',
    })

const [newFiles , setNewFiles] = useState([]);

// handle the file upload
    const handleChangeOfFile=(event)=>{
        setError({...error,"file":''});
        const value = event.target.files[0];
       
        if(value && value.size > 2 * 1024 * 1024){
            // inputFileRef.current.value = "";
            event.target.value = "";
            setError({...error,"file":'File size cannot be greater than 2 MB'});
        }
        else{
            console.log("visitdata------",newFiles);
            {newFiles.length === 0? value.id = 1 : value.id = newFiles.length+1}
            setNewFiles([...newFiles,value]);
        }
    }

    // remove the added files from the array
    const handleRemoveFiles=(id)=>{
        setNewFiles(newFiles.filter((item)=> item.id !== id))
    }
    // remove  view  data 
    async function  removeFileBadge(filename) { 
        const index =  file.findIndex(f => f.filename === filename) ;
        var  tempFiles =  file;
        tempFiles.splice(index, 1)
        const res =await removeFile(visitId , filename)
        alert(res)
        if (res) setFile(tempFiles);
    }

    return (
        <>
            <CommonAlert isOpen={openAlert} setOpen={setOpenAlert} alertMessage={"Updated Successfully"} />

            <div
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex="-1"
                aria-hidden={!open}
                className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full bg-gray-800 bg-opacity-50 h-full ${open ? 'flex' : 'hidden'}`}
            >
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow">
                        {/* Modal header */}
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Update visit
                            </h3>
                            <button
                                type="button"
                                className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/* Modal body */}
                        <div className="flex flex-col px-2 gap-2 mt-3">
                            <p className='text-lg ml-2'>Select Institution  <span className="text-red-500">*</span></p>
                            <div className='w-full flex flex-row justify-between gap-5 px-4 items-center'>
                                <CustomSelectList items={organizations} name={'institution'} onChange={handleListSelection} value={visitdata.institution} refv={inputRef} invalidMessage={error.institution} />
                                <button onClick={openInstModal} className='bg-blue-400 px-2 py-2 rounded-md text-white font-semibold w-[70px]'>Add</button>
                            </div>
                        </div>
                        <div className="flex flex-col px-2 gap-2 mt-3">
                            <p className='text-lg ml-2'>Select Employee  <span className="text-red-500">*</span></p>
                            <div className='w-full flex flex-row justify-between gap-5 px-4 items-center'>
                                <CustomSelectList items={employees} name={'employeeId'} onChange={handleListSelection} value={visitdata.employeeId} ref={inputRef} invalidMessage={error.employeeId} />
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 px-4 mt-3">
                            <p className='text-lg ml-2'>Date  <span className="text-red-500">*</span></p>
                            <TextInput placeholder={'Date'} label={'Date'} onChange={handleChangeText} name={'visitDateTime'} type={'datetime-local'} value={visitdata.visitDateTime} ref={inputRef} invalidMessage={error.visitDateTime} />
                        </div>

                        <div className="flex flex-col gap-2 px-4 mt-3">
                            <p className='text-lg ml-2'>Purpose of Visit  <span className="text-red-500">*</span></p>
                            <TextInput placeholder={'Purpose of Visit'} label={'Purpose of Visit'} type={'text'} onChange={handleChangeText} name={'purpose'} value={visitdata.purpose} ref={inputRef} invalidMessage={error.purpose} />
                        </div>

                        <div className="flex flex-col px-2 gap-2 mt-3">
                            <p className='text-lg ml-2'>Contact Person  <span className="text-red-500">*</span></p>
                            <div className='w-full flex flex-row justify-between gap-5 px-4 items-center'>
                                <CustomSelectList refv={contactPersonRef} items={contactPerson} name={'contactPersonId'} onChange={handleListSelection} ref={inputRef} value={visitdata.contactPersonId} invalidMessage={error.contactPersonId} />
                                <button onClick={()=>{openAddPesonModal(organizationId.current)}} className='bg-blue-400 px-2 py-2 rounded-md text-white font-semibold w-[70px]'>Add</button>
                            </div>
                        </div>

                        <div className="flex flex-col px-2 gap-2 mt-3">
                            <p className='text-lg ml-2'>Status <span className="text-red-500">*</span> </p>
                            <div className='w-full flex flex-row justify-between gap-5 px-4 items-center'>
                                <CustomSelectList items={visitDataOptions} name={'visitStatus'} onChange={handleListSelection} ref={inputRef} value={visitdata.visitStatus} invalidMessage={error.visitStatus} />
                                {/* <button onClick={openAddPesonModal} className='bg-blue-400 px-2 py-2 rounded-md text-white font-semibold w-[70px]'>Add</button> */}
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
                            <p className='text-lg ml-2'>Adding Note <span className="text-red-500">*</span></p>
                            <TextInput placeholder={'Adding Note'} label={'Adding Note'} type={'text'} onChange={handleChangeText} name={'additionalNotes'} value={visitdata.additionalNotes} ref={inputRef}  invalidMessage={error.additionalNotes}/>
                            {/* <Textarea/> */}
                        </div>

                        <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
                            <p className='text-lg ml-2'>Discussion Topic</p>
                            <TextInput placeholder={'Discussion Topic'} label={'Discussion Topic'} type={'text'} onChange={handleChangeText} name={'discussionTopic'} value={visitdata.discussionTopic} ref={inputRef}  />
                            {/* <Textarea/> */}
                        </div>
                        <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
                            <p className='text-lg ml-2'>Upload Documents </p>
                            <div class="bg-white px-4 flex items-center flex-wrap">
                                {file &&(file.length !== 0 &&
                                    file.map((item) => (
                                       
                                       <FileBadge
                                            name={item.originalname}
                                            filename={item.name}
                                            originalname={item.originalname}
                                            handleRemove={() => {
                                                removeFileBadge(item.filename);
                                            }}
                                            path={item.path}
                                        />
                                        
                                    )))}
                                {
                                    newFiles.map((item,index)=>(
                                        item.name &&(
                                            <FileBadge
                                                name={item.name}
                                                filename={item.name}
                                                originalname={''}
                                                handleRemove={() => {
                                                    handleRemoveFiles(item.id)
                                                }}
                                                />
                                        )
                                    ))
                                }
                            </div>
                            <div className='flex justify-end gap-1  items-center mb-[-5px]'> 
                            <svg width="13" height="13" viewBox="0 0 24 24" fill="blue" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C7.68 19.2 4.8 16.32 4.8 12C4.8 7.68 7.68 4.8 12 4.8C16.32 4.8 19.2 7.68 19.2 12C19.2 16.32 16.32 19.2 12 19.2ZM11 10H13V18H11V10ZM11 7H13V9H11V7Z"/>
                            </svg>
                                <p className='text-[12px] text-[#3f4cff]'>Upload a file with maximum size of 2 MB</p>
                            </div>

                            {/* <TextInput placeholder={'Doc Upload'} label={'Doc Upload'} type={'file'} onChange={handleChangeOfFile} name={'file'} ref={inputFileRef} /> */}
                            <div className=" relative">
                                <label className=" absolute top-3 left-3  bg-blue-500 hover:bg-blue-600 py-1 px-4 text-sm rounded-lg text-white font-semibold">
                                    <input label={"Doc Upload"}
                                        name={"file"}
                                        onChange={handleChangeOfFile}
                                        refv={inputFileRef}  type="file" id="formId" hidden 
                                    />
                                    Browse
                                </label>
                                <input
                                    placeholder="Upload File.."
                                    className="text-md block px-24 py-1 rounded-lg w-full border-2 border-gray-300 placeholder-gray-700 shadow-md focus:outline-none h-14"
                                    disabled
                                />       
                            </div>
                            <p className='text-[12px] text-red-500 mt-[-7px] ml-4'>{error.file}</p>
                            {/* <Textarea/> */}
                        </div>
                        {/* bottom buttuns  */}
                        <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={handleaddNewVist}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationMessage open={openConfirmationModal} onClose={() => { setOpenConfirmationModal(false) }} handleSubmit={submisionForCompleted} />
        </>
    );
};

EditVisit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EditVisit;