import React from "react";
import Chart from "react-apexcharts";

const PiCharts = ({ items }) => {
  let seriesData = [0, 0, 0, 0]; // Default values if items is undefined or null

  if (items) {
    seriesData = [
      items.upcomingVisitCount || 0,
      items.completedVisitCount || 0,
      items.pendingVisitCount || 0,
      items.actionNeededVisitCount || 0,
    ];
  }

  const labels = ["Upcoming", "Completed", "Pending", "Action Needed"];
  const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560"]; // Customize these colors as needed

  const data = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "40%", // Adjust this value to increase or decrease the thickness
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // Disable default data labels
      },
      tooltip: {
        enabled: true,
      },
      labels: labels,
      colors: colors,
    },
    series: seriesData,
  };

  const renderCustomLegend = () => {
    return labels.map((label, index) => (
      <div key={index} className="flex items-center mb-2">
        <div
          style={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: colors[index],
            marginRight: "5px",
          }}
        ></div>
        <div className="text-[12px]">{label}</div>
      </div>
    ));
  };

  return (
    <div className="flex items-center">
      <Chart options={data.options} series={data.series} type="donut" width="200" />
      <div className="-ml-5">
        {renderCustomLegend()}
      </div>
    </div>
  );
};

export default PiCharts;
