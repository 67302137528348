import React from 'react'
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Authgurd = ({component}) => {
const [status , setStatus] = useState(false);
const navigate = useNavigate();

useEffect(()=>{
    const CheckToken =async()=>{
        try{
            let token = localStorage.getItem('token');
            if(token){
                setStatus(true);
            }
            else{
                navigate('/');
            }
        }
        catch(error){
            navigate('/');
        }
    }
    CheckToken();

},[])
  return status ? <>{component}</> :<></>;
}

export default Authgurd;