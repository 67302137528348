import React, { useEffect, useState } from 'react'
import UserListTable from '../../components/Admin/UserListTable'
import AddEmployee from '../../components/Admin/AddEmployee';
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import { getAllEmployeeDetails } from '../../api/get/Admin';

const AdminUserList = () => {
    const title = ['#','EmployeeId','Employee Name','Email','Designation','Role','Action'];
   
    const [openAddEmpModal , setOpenAddEmpModal] = useState(false);
    // open the modal of add employee
    const handleOpenAddEmpModal=()=>{
        setOpenAddEmpModal(true);
    }

    const [employeeData, setEmployeeData] = useState([]);
    useEffect(()=>{
        getAllEmployee();
        
    },[])
    const getAllEmployee =async()=>{
        const employee = await getAllEmployeeDetails();
       
        if(employee.data){
            const empFormated = employee.data.employee.map((item,index)=>({
                ...item,
                orderNo:index+1
            }))
            console.log("all--emp--dataa",employee.data);
            setEmployeeData(empFormated);
        }
    }

  return (
   <>
   <Navbar title={'Employee List'}/>
   <Sidebar/>
     <div className=' sm:ml-[240px] pt-[65px] py-5'>
        <div className='px-5 py-3 flex justify-end'>
            <button onClick={handleOpenAddEmpModal} type="button" class="flex  items-center gap-2 text-white bg-[#1D4ED8] hover:bg-[#1D4ED8] focus:ring-4 focus:ring-[#1D4ED8] rounded-lg text-[10px] md:text-sm px-2 md:px-5 py-2.5 me-2 mb- focus:outline-none dark:focus:ring-[#624DE3] h-[40px] w-[100px] md:w-[180px] font-semibold ">
                <img src={require('../../assets/plusIcon.svg').default} alt='' className='w-[13px] hidden sm:inline sm:w-auto' />
                    Add Employee
            </button>
        </div>
        <div className='py-5'>
        <UserListTable title={title} content={employeeData} />
        </div>
    </div>
    <AddEmployee open={openAddEmpModal} onClose={()=>{setOpenAddEmpModal(false)}} />
   </>
  )
}

export default AdminUserList
