import React, { useEffect, useState } from 'react'
import InstitutionTable from '../../components/Admin/InstitutionTable'
import Navbar from '../../components/navbar'
import Sidebar from '../../components/sidebar'
import { getAllOrganizationList } from '../../api/get/Admin'

const InstitutionList = () => {
    const title = ['#','Institution Name','Email','Place','District','State','Contact Person','Action'];
  const [orgList , setOrgList] = useState([]);
    useEffect(()=>{
      fetchAllOrganizations();
    },[])
    const fetchAllOrganizations=async()=>{
      const org = await getAllOrganizationList();
      if(org.data){
        console.log("org----admin",org.data.organizations);
        const orgdata = org.data.organizations;
        const orgDataWithId = orgdata.map((item,index)=>({
          ...item,
          orderNo:index+1
      }))
        setOrgList(orgDataWithId);
      }
    }
      
  
  return (
   <>
   <Navbar title={'Institution List'}/>
   <Sidebar/>
     <div className='ml-[240px] pt-[65px]'>
      <div className='py-3 px-1'>
        <InstitutionTable title={title} content={orgList} />
      </div>
    </div>
   </>
  )
}

export default InstitutionList
