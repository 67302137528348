import axios from "axios"
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function getOrgType(orgId) {
    try {
        const response = await axios.post(`${url}/api/associates/organization/type`, {

        }, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        return response;
    }
    catch (error) {
        console.log(error);
    }
}