import { useState } from "react";
import TextInput from "../common/TextInput"


const CalenderComponent = ({handleSubmit}) => {
    const [dateFilter , setDateFilter] = useState({
        fromDate:'',
        toDate:''
    });

const handleChange=(e)=>{
    const {name , value } = e.target;
    setDateFilter({...dateFilter,[name]:value})
}
  return (
    <div className='flex flex-row justify-between border border-gray-300 gap-4 p-2 '>
        <div className="flex flex-col gap-3 sm:flex-row w-10/12 sm:w-auto">
            <div className="flex flex-row items-center gap-2 ">
                <p>From</p>
                <TextInput 
                    placeholder={'Date'}
                    type={'date'}
                    name={'fromDate'}
                    onChange={handleChange}
                />
            </div>
            <div className="flex flex-row items-center gap-6 sm:gap-2">
                <p>To</p>

                <TextInput 
                    placeholder={'Date'}
                    type={'date'}
                    name={'toDate'}
                    onChange={handleChange}
                />
            </div>
        </div>
     <div className="flex flex-row items-center">
        <button onClick={()=>handleSubmit(dateFilter)} class="bg-[#3C80F7] rounded-md text-[12px]  text-white font-bold px-2 py-2 w-[50px] h-[30px]">
            Go
        </button>
     </div>
    </div>
  )
}

export default CalenderComponent
