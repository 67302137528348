import React, { useState, useEffect } from 'react';
import CustomSelectList from '../common/CustomSelectList';
import TextInput from '../common/TextInput';
import { updateFollowUpAction } from '../../api/update/followUpAction';

function EditModal({ open, setter, followUpData , reset , setReset , visitId , updatedata}) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setter(false);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const [values, setValues] = useState({
    actionDetails: '',
    actionDescription: '',
    actionStatus: '',
    actionDueDate: new Date().toISOString(),
  });

  useEffect(() => {
    if (followUpData) {
      setValues({
        actionDetails: followUpData.actionDetails || '',
        actionDescription: followUpData.actionDescription || '',
        actionStatus: followUpData.actionStatus || '',
        actionDueDate: followUpData.actionDueDate || new Date().toISOString(),
        followUpActionId : followUpData.followUpActionId 
      });
    }
  }, [followUpData]);

  async function update() {
    const response = await updateFollowUpAction(values);
    console.log(response.data);
    setReset(!reset);
    updatedata(visitId);
    if(response.data.success ===  true ) closeModal();
  }

  const status = [
    { 0: "Pending" },
    { 1: "Success" },
    { 2: "Action needed" }
  ];

  const handleTextChange = (e) => {
    const { name, value, type } = e.target;
    setValues({
      ...values,
      [name]: type === 'datetime-local' ? new Date(value).toISOString() : value,
    });
    console.log(values);
  };

  const handleChangeOfList = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    console.log({ ...values, [name]: value });
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-10/12  sm:w-1/2">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Update Follow Up Action</h2>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700 focus:outline-none">
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div>
              <div className="flex flex-col gap-2 px-4 mt-3">
                <p className='text-lg ml-2'>Action Details</p>
                <TextInput placeholder={'Action Details'} name={'actionDetails'} label={'Action Details'} type={'text'} value={values.actionDetails} onChange={handleTextChange} />
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-2 px-4 mt-3">
                <p className='text-lg ml-2'>Action Description</p>
                <TextInput placeholder={'Action Description'} name={'actionDescription'} label={'Action Description'} type={'text'} value={values.actionDescription} onChange={handleTextChange} />
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-2 px-4 mt-3">
                <p className='text-lg ml-2'>Action Due Date</p>
                <TextInput placeholder={'Action Due Date'} name={'actionDueDate'} label={'Action Due Date'} type={'datetime-local'} value={values.actionDueDate.substring(0, 16)} onChange={handleTextChange} />
              </div>
            </div>
            <div className="flex flex-col gap-2 px-4 mt-3">
              <p className='text-lg ml-2'>Status</p>
              <CustomSelectList items={status} name={'actionStatus'} value={values.actionStatus.toString()} onChange={handleChangeOfList} />
            </div>
            <div className="mt-6 text-right">
              <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 focus:outline-none" onClick={closeModal}>
                Cancel
              </button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none" onClick={()=>{update();}}>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditModal;
