import React, { useEffect, useState } from 'react'
import TextInput from '../../components/common/TextInput';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { resetPasswordById, userEmailFetch } from '../../api/auth/Password';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {

  const {userid,date} = useParams();
  const navigate = useNavigate();
  const [expr , setExpr] = useState(false);
  
const [password , setPassword] = useState({
    newPassword:'',
    confirmPassword:''
  })

  const [error , setError ] = useState({
    newPassword:'',
    confirmPassword:''
  });

  const[userEmail , setUserEmail] = useState('');

  useEffect(()=>{
    userEmailget()
  },[])

  const userEmailget =async()=>{
    const user = await userEmailFetch(userid,date);
    console.log("user--",user)
    if(user.data){
      if(user.data.valid ===true){
        setExpr(true);
      }
      else{
        setExpr(false)
      }
      setUserEmail(user.data.email);
    }
  }

  // handle data input 
const handleTextChange=(e)=>{
    const {name , value} = e.target;
    setError({...error,newPassword:'' , confirmPassword:''})
    if(name === 'confirmPassword' && password.newPassword !== value){
      setError({...error,'confirmPassword':'Password Mismatch'})
    }
    setPassword({...password,[name]:value});
  }

  const resetPassword= async()=>{
    if(password.newPassword === '' || password.confirmPassword === ''){
      setError({...error,newPassword:'Required', confirmPassword:'Required'})
    }
    else{
      if(password.newPassword === password.confirmPassword){
        const passWordReset = await resetPasswordById(userid,password.newPassword,date);
        if(passWordReset.data){
          toast.success(passWordReset.data.message);
          setPassword({newPassword:'',confirmPassword:''})
          navigate('/')
        }
      else{
        toast.error(passWordReset.response.data.message);
        setError({...error,newPassword:passWordReset.response.data.message,confirmPassword:passWordReset.response.data.message})
        }
      }
      else{
        setError({...error,confirmPassword:'Password Mismatch'})
      }
  }
  }
  return (
    <div className='w-full h-screen flex flex-col justify-start items-center'>
    {expr === true ?(
      <>
    <div className='flex flex-col border bg-white p-5 w-11/12 sm:w-10/12 md:w-[700px] gap-2 rounded-lg shadow-xl mt-5 sm:mt-10 '>
       <div className='flex justify-center'>
           <h2 className='text-2xl font-semibold'>Reset Your Password</h2>
       </div>
       <div className='grid grid-cols-1 sm:grid-cols-[0.3fr_1fr] gap-2 sm:gap-4 mt-4'>
           <div className='flex items-center'>
               <p className='text-nowrap text-gray-500'>Email Id</p>
           </div>
           <div>
             <input 
                 className="peer h-12 min-w-[200px] w-full bg-white rounded-[7px] border border-gray-300 px-3 py-2.5 font-sans text-sm font-normal text-gray-400" 
                 type={'text'}
                 value={userEmail}
                 disabled={true}          
             />
           </div>
       </div>
       <div className='grid grid-cols-1 sm:grid-cols-[0.3fr_1fr] gap-2 sm:gap-4 mt-4'>
           <div className='flex items-center'>
               <p className='text-nowrap text-gray-500'>New Password</p>
           </div>
           <div>
           <TextInput placeholder={'New Password'} name={'newPassword'} label={'New Password'} type={'password'} onChange={handleTextChange}  />
             <p className='text-[10px] text-red-500 ml-2'>{error.newPassword}</p>
           </div>
       </div>
       <div className='grid grid-cols-1 sm:grid-cols-[0.3fr_1fr] gap-2 sm:gap-4 mt-4'>
           <div className='flex items-center'>
               <p className='text-nowrap text-gray-500' >Confirm Password</p>
           </div>
           <div>
             <TextInput placeholder={'Confirm Password'} name={'confirmPassword'} label={'Confirm Password'} type={'password'} onChange={handleTextChange}  />
             <p className='text-[10px] text-red-500 ml-2'>{error.confirmPassword}</p>
           </div>
       </div>
       <div className='flex justify-end gap-3'>
         <button className=' className="py-2.5 px-5 ms-3 text-sm font-bold text-white  bg-gray-800 rounded-lg border  dark:hover:text-white"'>Cancel</button> 
         <button  
           onClick={resetPassword}
           type="button"
           className="text-white bg-[#3dadd3] hover:bg-[#32B1DC] font-bold rounded-lg text-sm px-5 py-2.5 text-center">
           Reset
         </button>
       </div>
    </div>
    <div className='py-10 px-5'>
      <p className='text-[15px] text-center text-red-500'>Password must contain at least one uppercase letter, one digit, and be at least 7 characters long</p>
    </div>
    </>
    ):
    <div className='w-10/12 flex flex-col gap-3 justify-center items-center mt-10 py-5'>
      <h3 className='text-[20px] font-semibold sm:text-[25px] text-center'>Oops! Your Password reset link has expired.</h3>
      <p className='text-[13px] sm:text-[16px] text-center'> To reset your password, return to the login page and select "forgot password" to send a new eamil. </p>
    </div>
    }
      <ToastContainer 
         position='top-right'
         autoClose={3000}    
      />
    </div>
  )
}

export default PasswordReset;
