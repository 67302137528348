import React from 'react';
import Milestoneinteraction from '../components/milestoncomponent'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';

const App = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {

    navigate('/institution'); // Redirect to the landing page
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Navbar */}
      <header className="bg-white text-black p-4 flex justify-between items-start">
        <h1 className="text-xl font-bold">Dashboard</h1>
        <button
          onClick={handleSignOut}
          className="bg-gray-600 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
        >
          Sign Out
        </button>
      </header>

      <div className="flex flex-grow overflow-hidden">
        {/* Sidebar */}
        <aside className="bg-gray-700 text-white w-64 p-4 flex-shrink-0">
          <nav>
            <ul>
              {/* Add your sidebar links here */}
            </ul>
          </nav>
        </aside>

        {/* Main content area */}
        <main className="flex-grow overflow-auto p-4 bg-gray-100">
          {/* Milestone Interaction Component */}
          <Milestoneinteraction />
        </main>
      </div>
    </div>
  );
};

export default App;
