import React from 'react';

const Tooltip = ({ children, text }) => {
    
        const tooltipStyle = {
          position: 'relative',
          display: 'inline-block',
        };
      
        const tooltipTextStyle = {
          visibility: 'hidden',
          backgroundColor: 'black',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '6px',
          padding: '5px ',
          position: 'absolute',
          zIndex: '1',
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
       
          
        };

    return (
        <div style={{ textAlign: 'center' }}>
            <div
                style={tooltipStyle}
                onMouseEnter={e => {
                    e.currentTarget.querySelector('span').style.visibility = 'visible';
                }}
                onMouseLeave={e => {
                    e.currentTarget.querySelector('span').style.visibility = 'hidden';
                }}
            >
                {children}
                <span style={tooltipTextStyle}>{text}</span>
            </div>
        </div>
    );
};

export default Tooltip;
