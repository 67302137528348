import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

export const addOrganization =async(institution)=>{
    try{
        const addInst = await axios.post(`${url}/api/associates/organization/create`,institution,{
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
        
        return addInst;
    }
    catch(error){
        console.error(error);
        return error;
    }
}