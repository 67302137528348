import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationToast = ({ type, message,count}) => {
    useEffect(() => {
        console.log("Clicked________$")
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            case 'info':
                toast.info(message);
                break;
            case 'warning':
                toast.warn(message);
                break;
            default:
                toast(message);
                break;
        }
    }, [type, message,count]);

    return (
        <ToastContainer />
    );
};

export default NotificationToast;
