// src/PhoneInput.js
import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const PhoneInputComponent = ({onChange , name,refv }) => {
  const [phone, setPhone] = useState('');


  return (
    <PhoneInput
      country={'in'}
      ref={refv}
      value={phone}
      onChange={phone => {setPhone(phone) ;onChange({target :  {name , value: phone}})} }
      inputStyle={{ width:"100%" , hight : "100%"}}
      // Optional props for customization
      // preferredCountries={['us', 'gb']}
      // enableSearch={true}
    //className="peer h-full w-full bg-white rounded-[7px] border border-gray-300 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-900 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-300 placeholder-shown:border-t-violet-200 focus:border-2 focus:border-gray-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
      style={{ width: '100' , hight : "100"}}
    />
  );
};

export default PhoneInputComponent;