import axios from 'axios';
import { urlLocal } from '../../url/Url';

const url =urlLocal();

export const fetchOrganizationTypes = async () => {
  try {
    const response = await axios.get(`${url}/api/associates/organization-types`);
    return response.data;
  } catch (error) {
    console.error('Error fetching organization types:', error);
    throw error;
  }
};

export const fetchOrganizationsByType = async (type) => {
  try {
    const response = await axios.get(`${url}/api/associates/organizations/type/${type}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};

export const fetchVisitDetails = async (type, organization) => {
  try {
    const response = await axios.get(`${url}/api/associates/visit-details/${type}/${organization}`);
    return response.data ;
  } catch (error) {
    console.error('Error fetching visit details:', error);
    throw error;
  }
};
