import axios from "axios";
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function getUser() {
    try {
        const response = await axios.post(`${url}/api/associates/employee/get`, {

        }, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
    }
}