import React, { useState, useEffect, useRef } from "react";
import CustomSelectList from "../common/CustomSelectList";
import TextInput from "../common/TextInput";
import { fetchDesignation, fetchTheEmployeeByDepartment, getAllDepartmentList } from "../../api/get/Admin";
import { createNewUsers } from "../../api/insert/Admin";

const EditEmployee = ({ open, onClose ,employeeId }) => {

  const [departmentList , setDepartmentList ] = useState([]);
  const [designationList , setDesignationList] = useState([]);
  const [employeeData , setEmployeeData] = useState({
    fullName:'',
    email:'',
    userid:'',
    departmentId:'',
    designationId:'',
    role:'',
    parent:'',
    ictEmployeeId:''
  });
  useEffect(()=>{
    console.log("clicked---------",employeeId);
    getDepartmentData();
  },[open])
  // list all department
  const getDepartmentData=async()=>{
    const department = await getAllDepartmentList();
    if(department.data){
      const dept =[];
      department.data.departments.forEach(element => {
        var obj ={};
        obj[element._id] = element.name;
        dept.push(obj)
      })
      setDepartmentList(dept);
    }
  }
// get designation
const getDesignationById =async(deptId)=>{
  const designation = await fetchDesignation(deptId);
  const desigData =[];
  if(designation.data){
    console.log("----designation----",designation.data);
    designation.data.designations.forEach((item)=>{
      var obj ={};
      obj[item._id] = item.name;
      desigData.push(obj);
    })
    setDesignationList(desigData);
  }
}
const [parentEmp , setParentEmp ] = useState([]);
 // fetch the employee list based on department
const getTheEmployeeDataByDeptId= async(deptId)=>{
  const employee = await fetchTheEmployeeByDepartment(deptId);
  const emp =[];
  if(employee.data){
    console.log("empl-----list----",employee);
    employee.data.employeeList.forEach(item=>{
      var obj={};
      obj[item._id] = item.userId.fullName;
      emp.push(obj);
    })
    setParentEmp(emp);
  }
}
  // handle the selection of department
  const handleSelect=(e)=>{
    const {name , value} = e.target;
    if(name ==='departmentId' ){
      getDesignationById(value);
      getTheEmployeeDataByDeptId(value);
    }
    setEmployeeData({...employeeData,[name]:value});
  }

  const handleAddEmployee=async()=>{
    // const create = await createNewUsers(employeeData);
    // console.log("---create--users---",create)
    // if(create.data){
    //   setEmployeeData({
    //     fullName:'',
    //     email:'',
    //     userid:'',
    //     departmentId:'',
    //     designationId:'',
    //     role:'',
    //     parent:'',
    //     ictEmployeeId:''
    //   })
    //   alert(create.data.message);
    //   onClose();
    // }
    // else{
    //   alert(create.response.data.message);
    // }
  }

  return (
    <>
      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full bg-gray-800 bg-opacity-50 md:inset-0 h-full ${
          open ? "flex" : "hidden"
        }`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow-2xl">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900">
                Add Employee
              </h3>
              <button
                type="button"
                className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-400 dark:hover:text-white"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="flex flex-col gap-2 px-4 mt-3">
                <p className="text-lg ml-2">
                    Employee Name <span className="text-red-500">*</span>
                </p>
                <TextInput
                    placeholder={"Employee Name"}
                    label={"Employee Name"}
                    type={"text"}
                    onChange={handleSelect}
                    name={"fullName"}
                    //invalidMessage={invalidMessages.purpose}
                />
            </div>
            <div className="flex flex-col gap-2 px-4 mt-3">
                <p className="text-lg ml-2">
                   Email Id <span className="text-red-500">*</span>
                </p>
                <TextInput
                    placeholder={"Email Id"}
                    label={"Email Id"}
                    type={"text"}
                    onChange={handleSelect}
                    name={"email"}
                    //invalidMessage={invalidMessages.purpose}
                />
            </div>
            <div className="flex flex-col gap-2 px-4 mt-3">
                <p className="text-lg ml-2">
                  UserId <span className="text-red-500">*</span>
                </p>
                <TextInput
                    placeholder={"User Id"}
                    label={"User Id"}
                    type={"text"}
                    onChange={handleSelect}
                    name={"userid"}
                    //invalidMessage={invalidMessages.purpose}
                />
            </div>
            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
                Select Department <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                //   refv={employeeRef}
                  items={departmentList}
                  name={"departmentId"}
                  onChange={handleSelect}
                  //invalidMessage={invalidMessages.employeeId}
                />
              </div>
            </div>
            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
                Select Designation <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                  //refv={employeeRef}
                  items={designationList}
                  name={"designationId"}
                  onChange={handleSelect}
                  //invalidMessage={invalidMessages.employeeId}
                />
              </div>
            </div>
            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
                Select Role <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                  //refv={employeeRef}
                  items={[{Manager:'Manager'},{Associates:'Associates'}]}
                  name={"role"}
                  onChange={handleSelect}
                  //invalidMessage={invalidMessages.employeeId}
                />
              </div>
            </div>
            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
               Reported To <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                  //refv={employeeRef}
                  items={parentEmp}
                  name={"parent"}
                  onChange={handleSelect}
                  //invalidMessage={invalidMessages.employeeId}
                />
              </div>
            </div>
           
            <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
              <p className="text-lg ml-2">
                ICT Employee Id <span className="text-red-500">*</span>
              </p>
              <TextInput
                placeholder={"ICT EmployeeId"}
                label={"ICT EmployeeId"}
                type={"text"}
                onChange={handleSelect}
                name={"ictEmployeeId"}
                invalidMessage={""}
              />
            </div>

            {/* bottom buttuns  */}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={handleAddEmployee}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployee;
