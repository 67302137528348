import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
// import animation from "../animation/visitComplete.json";

function AlertBox({ isOpen, setOpen, alertMessage  , buttonTheme ,animation}) {
  const lottieRef = useRef();

  // useEffect to reset or control the Lottie animation based on isOpen
  useEffect(() => {
    if (lottieRef.current) {
      if (isOpen) {
        // Reset or reload animation when the alert box opens
        lottieRef.current.setSpeed(0.5)
        lottieRef.current.goToAndPlay(0, true); // Go to the start and play
      } else {
        // Optional: Stop animation when the alert box is closed
        lottieRef.current.stop();
      }
    }
  }, [isOpen]);

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden={isOpen}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 bg-gray-800 bg-opacity-50 h-full ${
        isOpen ? "flex" : "hidden"
      }`}
    >
      <div className="border rounded-lg shadow relative max-w-sm bg-white">
        <div className="flex justify-end p-2">
          <button
            type="button"
            className="text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={() => setOpen(false)}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="p-6 pt-0 text-center">
          <Lottie
            lottieRef={lottieRef}
            animationData={animation}
            loop={false}
            style={{ width: "30vh", height: "30vh", margin: "auto" }}
          />
          <h3 className="text-xl font-normal text-gray-500 mt-5 mb-6">
            {alertMessage}
          </h3>
          <button
            className={"text-white  hover:ring-4 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2"+buttonTheme}
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          {/* <button
            className="text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-cyan-200 border border-gray-200 font-medium inline-flex items-center rounded-lg text-base px-3 py-2.5 text-center"
          >
            No, cancel
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default AlertBox;
