import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { removeVisit } from "../../api/remove/visit";
import Tooltip from "../common/Tooltip";
import { FaEye, FaTrash, FaEdit } from "react-icons/fa";
import ViewVisit from "../common/ViewVisit";
import { urlLocal } from "../../url/Url";

const InstitutionsLogsTable = ({
  title,
  content,
  handleOpenFollowUPModal,
  handleOpenModal,
  updateFunction,
}) => {
  const url = urlLocal();
  const [currentPage, setCurrentPage] = useState(1);
  let order = 0;
  const itemsPerPage = 10;

  // Calculate the indexes for slicing the data array
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = content.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(content.length / itemsPerPage);

  // function for extracting date
  function extractDate(dateTimeString) {
    const date = new Date(dateTimeString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }

  //   function for extracting time
  function extractTime(dateTimeString) {
    var now = new Date(dateTimeString);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return  now ;
  }

  async function removeVisitEvent(id) {
    try {
      const response = await removeVisit(id);
      console.log(response);
      await updateFunction();
    } catch (error) {
      console.error(error);
    }
  }

  const [viewModal, setViewModal] = useState(false);
  const [viewId, setViewId] = useState(undefined);

  function openView(id) {
    setViewId(id);
    setViewModal(true);
  }

  return (
    <div class="relative overflow-x-auto shadow-2xl sm:rounded-lg w-full">
      {viewId && (
        <ViewVisit
          showModal={viewModal}
          setShowModal={setViewModal}
          viewId={viewId}
        />
      )}
      <table class="w-full text-sm text-left rtl:text-right text-gray-900">
        <thead class="text-xs uppercase bg-white dark:text-gray-900">
          <tr>
            {title.map((data, index) => (
              <th scope="col" class="px-2 py-3 font-bold text-[15px]">
                {data}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((data, index) => (
            <tr
              class={`${index % 2 === 0 ? "bg-[#F7F6FE]" : "bg-white"}`}
              key={index}
            >
              <th
                scope="row"
                class="px-2 py-4 font-medium text-gray-900 whitespace-nowrap "
              >
                {data.orderNo}
              </th>
              <td class="px-6 py-4">
                {data.organisationId.organizationName +
                  " , " +
                  data.organisationId.place +
                  " , " +
                  data.organisationId.district}
              </td>
              <td class="px-6 py-4 flex items-center gap-2">
                <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                  <img
                    src={
                      data.employeeId.userId.profilePicture
                        ? url + "/" + data.employeeId.userId.profilePicture
                        : require("../../assets/userImg.svg").default
                    }
                    alt=""
                    className="h-full w-full"
                  />
                </div>
                {data.employeeId.userId.fullName}
              </td>
              {/* <td class="px-6 py-4">
                                    {data.purpose}
                                </td> */}
              <td class="px-6 py-4 text-[#0D99FF]">
                {data.contactPersonId.name}
              </td>
              <td class="px-3 py-4">
                <p className="w-[80px]">{extractDate(data.visitDateTime)}</p>
              </td>
              <td class="px-6 py-4 ">
                <p className="w-[80px]">
                  {new Date(data.visitDateTime).toLocaleTimeString()}{" "}
                </p>
              </td>
              <td class="px-6 py-4 ">
                <div className="bg-[#624DE3] h-[30px] w-full px-1 text-[12px] text-nowrap text-white rounded-2xl flex justify-center items-center">
                  {data.organisationId.organizationType.name}
                </div>
              </td>
              <td class="px-6 py-4">
                <div
                  className={`${
                    data.visitStatus === "Completed"
                      ? "bg-[#d8f8e6] text-[#16864b]"
                      : data.visitStatus === "Pending"
                      ? "bg-[#FEF2E5] text-[#CD6200]"
                      : "bg-[#FBE7E8] text-[#A30D11]"
                  } capitalize  h-[30px] w-full text-nowrap text-[12px] rounded-2xl flex justify-center items-center px-2`}
                >
                  {data.visitStatus}
                </div>
              </td>
              <td class="px-6 py-4 flex flex-row gap-2">
                <button
                  class="text-blue-700 hover:text-blue-300"
                  onClick={() => openView(data._id)}
                >
                  <Tooltip text={"View Details"}>
                    <FaEye />{" "}
                  </Tooltip>
                </button>

                <button
                  class="text-blue-700 hover:text-blue-300"
                  onClick={async () => removeVisitEvent(data._id)}
                  hidden={data.visitStatus === "Completed"}
                >
                  <Tooltip text={"Delete"}>
                    <FaTrash />{" "}
                  </Tooltip>
                </button>

                <button
                  hidden={data.visitStatus === "Completed"}
                  className="text-blue-700 hover:text-blue-300 w-[20px] h-[20px]"
                  onClick={async () => {
                    handleOpenModal({
                      visitId: data._id,
                      institution: data.organisationId._id,
                      visitDateTime:  extractTime(data.visitDateTime),
                      purpose: data.purpose,
                      contactPersonId: data.contactPersonId._id,
                      additionalNotes: data.additionalNotes,
                      visitType: data.visitType,
                      employeeId: data.employeeId._id,
                      discussionTopic: data.discussionTopic || "",
                      visitStatus: data.visitStatus,
                      file: data.file || undefined,
                    });
                    await updateFunction();
                  }}
                >
                  <Tooltip text={"Update"}>
                    <FaEdit />
                  </Tooltip>
                </button>

                <button
                  className=" w-[18px] h-[18px] my-auto mx-0"
                  onClick={() => {
                    handleOpenFollowUPModal(data._id);
                  }}
                >
                  <div class="relative inline-flex">
                    <Tooltip text={"Follow Up Action"}>
                      <img
                        src={require("../../assets/followup.png")}
                        width="auto"
                        height="auto"
                        alt=""
                      />
                    </Tooltip>
                    {data.followUpCount !== 0 && (
                      <span class="absolute rounded-full py-[.5] px-[.5] text-xs font-medium content-[''] leading-none grid place-items-center top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4 bg-[#589D56] text-white min-w-[24px] min-h-[24px]">
                        {data.followUpCount}
                      </span>
                    )}
                  </div>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <nav
        className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4 pl-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
          Showing{" "}
          <span className="font-semibold text-gray-900">
            {indexOfFirstItem + 1}-{indexOfLastItem}
          </span>{" "}
          of{" "}
          <span className="font-semibold text-gray-900">{content.length}</span>
        </span>
        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 m-0">
          <li>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight  bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100  dark:bg-gray-100 dark:border-gray-700 dark:text-gray-900 dark:hover:bg-gray-400 dark:hover:text-white"
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {[...Array(totalPages)].map((_, pageIndex) => (
            <li key={pageIndex}>
              <button
                onClick={() => handlePageChange(pageIndex + 1)}
                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                  currentPage === pageIndex + 1
                    ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-100 dark:text-gray-500"
                    : "text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-400 dark:hover:text-white"
                }`}
              >
                {pageIndex + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-900 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-400 dark:hover:text-white"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default InstitutionsLogsTable;
