// validate email
export const ValidateEmail=(email)=>{
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

//validate mobile number
export const validateMobileNumber =(mobilenumber)=>{
    const pattern = /^\d{12}$/;
    return pattern.test(mobilenumber)
}
//validate text 
export const validateText =(text)=>{
    return text.trim() !== '';
}

// validate the text contain numeric or special character then return false
export const isAlphabetic = (text) => {
    const pattern = /^[A-Za-z]+$/;
    return pattern.test(text);
};

// validate the place and institution name
export const validateInstitution =(text) => {
    const pattern = /^[A-Za-z\s().]+$/;
    const isValidLength = text.length >= 5;

    if (!isValidLength) {
        return { status: false, message:'Please enter the full name of the institution'};
    }

    if (!pattern.test(text)) {
        return { status: false, message: 'Only alphabets are allowed; allowed special characters are ((),.).'};
    }

    return { status: true, message: 'Text is valid' };
};

// validate the place
export const validatePlaceText =(text) => {
    const pattern = /^[A-Za-z\s]+$/;
    const isValidLength = text.length >= 3;

    if (!isValidLength) {
        return { status: false, message: 'Make sure that the place is meaningful.' };
    }

    if (!pattern.test(text)) {
        return { status: false, message: 'Make sure that the place contains only alphabetic characters.' };
    }

    return { status: true, message: 'Text is valid' };
};
// Function to check if the text contains any numeric characters
export const containsNumeric = (text) => {
    const pattern = /\d/;
    return !pattern.test(text);
};

//validate the text with check if it contain alphabets and restrict special character
export const isAlphabeticWithouSpecialCharacter =(text)=>{
 const pattern = /^(?=.*[A-Za-z])[A-Za-z0-9 .]+$/;
    return pattern.test(text);
}

export const nameValidation =(text)=>{
    const pattern = /^[a-zA-Z\s.]+$/;
    return pattern.test(text);
}