import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

export const getNotifications = async()=>{
    try{
        const notifications = await axios.post(`${url}/api/associates/notification/getAll`,{},{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        });
        console.info("noti" ,notifications)
        return notifications.data.notification;
    }
    catch(error){
        console.error(error);
        return error;
    }
}