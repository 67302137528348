import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

export const createNewUsers =async(employee)=>{
    try{
        const createuser = await axios.post(`${url}/api/admin/user/create`,{
            "username" : employee.userid,
            "password" : "test@123" ,
            "fullName" : employee.fullName ,
            "email" : employee.email,
            "role"  : employee.role ,
            "designationId" : employee.designationId,
            "employeeId" : employee.ictEmployeeId,
            "parentId" : employee.parent
        },{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return createuser;
    }
    catch(error){
        console.error(error);
        return error;
    }
}