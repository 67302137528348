import React, { useState } from 'react'
import Select from 'react-select'

const SearchableList = ({options,handleChange ,selectedData}) => {

const [selectOptions , setSelectOptions] = useState(selectedData);

const handleChangeList=(selectOptions)=>{
    setSelectOptions(selectOptions);
    console.log("selected list",selectOptions);
    handleChange(selectOptions);
}


return (
    <div className='w-full'>
      <Select 
        options={options}
        value={selectOptions}
        onChange={handleChangeList}
      />
    </div>
  )
}

export default SearchableList
