
import axios from 'axios';
import { urlLocal } from '../../url/Url';

const url = urlLocal();
const apiUpcomingVisits =async () => {
    var visitData = ""
    const loading = ""
    const errors = ""
    const response = await axios.get(`${url}/api/associates/upcoming-visits`,
        {
            headers:{
                Authorization: localStorage.getItem("token")
            }
        })
    console.log("res", response)
    visitData =  response.data;


    return { data : visitData, loading, errors };
};

export default apiUpcomingVisits;
