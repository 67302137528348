import axios from 'axios'
import { urlLocal } from '../../url/Url';

const url = urlLocal(); //local domain
//  api function for adding new visits
export const updateProfile = async (data) => {
    try {
        console.log("data" + data);
        const response  = await axios.post(`${url}/api/associates/profile/update`, data, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        });

        return response;
    }
    catch (error) {
        console.error(error)
        return error
    }
}