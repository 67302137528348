const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

async function success(pos) {
    const crd = pos.coords;
    return [crd.latitude, crd.longitude];
}

function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
}

export async function location() {
    // Wrap geolocation in a promise to handle async operation
    const getCurrentPosition = (options) => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    };

    if (navigator.geolocation) {
        const result = await navigator.permissions.query({ name: "geolocation" });

        if (result.state === "granted") {
            try {
                const pos = await getCurrentPosition(options);
                const [latitude, longitude] = await success(pos); // Calls the success function to get lat/lon

                return { hasAccess: true, latitude, longitude }; // Return as an object for clarity
            } catch (error) {
                if (error.code === 1) alert("Please enable location seamless useability ")
                else errors(error);
                return { hasAccess: false, latitude: 0, longitude: 0 };
            }
        } else if (result.state === "prompt") {
            try {
                const pos = await getCurrentPosition(options);
                const [latitude, longitude] = await success(pos);
                return { hasAccess: true, latitude, longitude };
            } catch (error) {
                if (error.code === 1) alert("Please enable location seamless useability ")
                else errors(error);
                return { hasAccess: false, latitude: 0, longitude: 0 };
            }
        } else if (result.state === "denied") {
            alert("Please enable location seamless useability ");
            return { hasAccess: false, latitude: 0, longitude: 0 };
        }
    } else {
        console.log("Geolocation is not supported by this browser.");
        return null;
    }
}


