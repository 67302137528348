

const ConfirmationMessage = ({ open, onClose,handleSubmit}) => {

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden={!open}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 backdrop-blur-sm h-full ${open ? 'flex' : 'hidden'}`}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow border border-gray-300">
         
          {/* Modal body */}
          <div className='w-full flex flex-col items-center gap-5 px-2 py-5'>
            
            <div className='flex flex-col justify-center items-center gap-2'>
                <h2 className="text-[27px] font-semibold">Are you sure ?</h2>
                <p className='text-[18px]  text-[#353536] font-[400] text-center'>Once you submit the status as completed, you cannot edit or delete  the visit.</p>
            </div>
            <div className="flex gap-3 ">
                <button onClick={handleSubmit} className='bg-[#2563EB] cursor-pointer hover:bg-[#1657e3] active:bg-[#1657e3] text-gray-100 hover:text-white px-2 py-1 sm:px-5 sm:py-2 rounded-lg font-semibold'>Confirm</button>
                <button onClick={onClose} className='bg-[#2e2f2e] cursor-pointer hover:bg-[#2f302f] active:bg-[#323332] text-gray-200 hover:text-white active:text-white px-2 py-1 sm:px-5 sm:py-2 rounded-lg font-semibold'>Cancel</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};



export default ConfirmationMessage ;
