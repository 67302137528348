import { Route, Routes ,Router} from 'react-router-dom';
import './App.css';

import Sidebar from './components/sidebar';
import LandingPage from './pages/LandingPage';
import Navbar from './components/navbar';
import Homepage from './components/Homepage';
import Layout from './layout/Layout';
import Dashboard from './pages/Dashboard';
import VisitLogs from './pages/VisitLogs';
import InstitutionLogs from './pages/InstitutionLogs';
import Profile from './pages/Profile';
import Page404 from './pages/404Page/Page404';
import Authgurd from './authgurd/Authgurd';
import PasswordReset from './pages/password/PasswordReset';
import ILandingPages from './pages/ILandingpages';
import ILayout from './layout/InstLayout';
import AdminUserList from './pages/admin/AdminUserList';
import InstitutionList from './pages/admin/InstitutionList';
  function App() {
    return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="homepage" element={<Authgurd component={<Layout />} />} />
      <Route path="layout" element={<Layout />} />
      <Route path='dashboard' element={<Dashboard/>} />
      <Route path='visitlogs' element={<Authgurd component={<InstitutionLogs/>}/> } />
      <Route path='institutionlogs' element={<Authgurd component={<VisitLogs/>} />} />
      <Route path='profile' element={<Authgurd component={<Profile/>} />} />
      <Route path='passwordreset/:userid/:date' element={<PasswordReset/>}/>
      {/* Admin Routes */}
      <Route path='adminEmployeeList' element={<AdminUserList/>} />
      <Route path='adminInstitution' element={<InstitutionList/>} />
      {/* Institusion Routes */}
      <Route path='/institution' element={<ILandingPages/>} />
      <Route path='/institution/layout' element={<ILayout/>}/>
      <Route path='*' element={<Page404/>} />
</Routes>

  );
}

export default App;
