import axios from "axios";
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function  getVisitDetails(visitId){
    try { 
        const response = await axios.post(`${url}/api/associates/visit/details` , {
            visitId : visitId
        } , {headers :  {
            Authorization :  localStorage.getItem("token")
        }});
        return response ; 
    }
    catch (error){
        console.log(error);
    }
}