import React, { useState } from "react";
import TextInputNormal from "../components/common/TextInputNormal";
import PasswordInput from "../components/common/PasswordInput";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userLogin } from "../api/auth/Ilogin";
import { useNavigate } from 'react-router-dom';

const ILandingPage = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({ email: '', password: '' });
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserCredentials((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = userCredentials;
  
    if (!validateEmail(email)) {
      setErrorMessage({ email: "Enter a valid email", password: '' });
      return;
    }

    setErrorMessage({ email: '', password: '' });
  
    try {
      const userauth = await userLogin(email, password);
      if (userauth.data.success) {
        toast.success(userauth.data.message);
        localStorage.setItem('token', userauth.data.token);
        navigate('/institution/layout', { state: { token: userauth.data.token } });
      } else {
        toast.error(userauth.data.message || "Login failed");
        setErrorMessage({ email: '', password: '' });
      }
    } catch (err) {
      console.error(err);
      setErrorMessage({ email: '', password: '' });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white sm:bg-[#004466] p-5">
      <div className="flex w-full max-w-5xl flex-col sm:flex-row justify-center bg-white p-5 rounded-xl shadow-lg">
        <div className="hidden sm:flex w-full flex-col justify-between bg-[#75aad7] p-10 rounded-l-xl">
          <div>
            <h2 className="text-4xl font-bold-alt text:bg-[#004466]">Visit App</h2>
            <p className="text-lg text-white mt-5 leading-8">
            Keep track of our interactions with your institution effortlessly. Our Visit Tracking App allows you to easily monitor, organize, and analyze our appointments and communications. Stay informed and organized with real-time updates and insights.
            </p>
          </div>
          <img src={require("../assets/ud.svg").default} alt="Institution Illustration" className="w-4/4 mx-auto mt-3" />
        </div>

        <div className="flex flex-col justify-start w-full p-5 sm:p-10 rounded-r-xl">
          <div className="w-1/2 mx-auto mb-10">
            <img src={require("../assets/ict-logo.svg").default} alt="ICT Logo" className="w-full" />
          </div>
          <h2 className="text-4xl font-bold text-[#75aad7] mb-5 ">Institution Login</h2>
          <h3 className="text-2xl font-bold text-[#2A2A2A] mb-10">Welcome Back!</h3>

          <form className="flex flex-col" onSubmit={handleSignIn}>
            <div className="mb-4">
              <label className="block text-lg font-medium mb-2">User Id</label>
              <TextInputNormal
                type="email"
                name="email"
                placeholder="User Id"
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                required
              />
              <p className="text-red-500 text-sm">{errorMessage.email}</p>
            </div>
            <div className="mb-4">
              <label className="block text-lg font-medium mb-2">Password</label>
              <PasswordInput
                placeholder="Password"
                name="password"
                onChange={handleInputChange}
                required
              />
              <p className="text-red-500 text-sm">{errorMessage.password}</p>
            </div>
            <button
              type="submit"
              className="w-full bg-[#75aad7] rounded-xl hover:bg-[#547896] text-white font-bold py-3 mt-5 transition duration-300"
            >
              Sign In
            </button>
          </form>

          <div className="flex flex-col items-center mt-10">
            <p className="text-gray-600 text-sm mb-2">Or Continue with Google</p>
            <div
              className="flex items-center border-2 border-[#32b1dc] py-2 px-4 rounded cursor-pointer hover:bg-gray-100 transition duration-300"
                onClick={()=>{document.location.href="/api/authenticate/google/signup/Employee"}}
           
            >
              <img src={require("../assets/google.png")} alt="Google Icon" className="w-6 h-6 mr-3" />
              <span className="text-[#32b1dc] font-semibold">Login With Google</span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default ILandingPage;
