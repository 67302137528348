import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

// add new Contact person
export const removeFile = async(visitId , fileName )=>{
    try{
        const newPerson =  await axios.post(`${url}/api/associates/visit/file/Remove/${visitId}/${fileName}`,{},{
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
        return true;
    }
    catch(error){
        console.error(error);
        return false;
    }
}