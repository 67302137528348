import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';
import Textarea from '../common/Textarea';
import CustomSelectList from '../common/CustomSelectList';
import { MdCancel } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { getFollowUpAction } from '../../api/get/followUpActions';
import Lottie from 'lottie-react';

import animationData from '../../animation/complete.json';
import EditModal from './EditModal';
import { removeFollowUpAction } from '../../api/remove/followUpAction';

const FollowUpActionModal = ({ open, onClose, addFollowUPAction, visitId, submitTrue, onAnimationCompleted }) => {
  const [followUpAction, setFollowUpAction] = useState({
    actionDetails: '',
    actionDescription: '',
    actionDueDate: '',
    addVisit: false
  });



  // handle the data input 
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFollowUpAction({ ...followUpAction, [name]: value });
  }
  const handleListSelection = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);
    setFollowUpAction({ ...followUpAction, [name]: value });

  }

  const inputRef = useRef(null);
  const descriptionRef = useRef(null);
  const dateRef = useRef(null);
  const statusRef = useRef(null);
  const handleReset = () => {

    console.log("hoi ", inputRef.current)
    inputRef.current.value = '';
    descriptionRef.current.value = "";
    dateRef.current.value = "";
    statusRef.current.value = "";

    setFollowUpAction({
      actionDetails: '',
      actionDescription: '',
      actionDueDate: '',
      addVisit: false,
      visitId : visitId
    });
    
    setInvalidMessage({
      "actionDueDate": "",
      "actionStatus": "",
      "status": "",
      "__v": "",
      "actionDescription": "",
      "actionDetails": ""
    })

  };

  const handleChangeOfCheckBox = (event) => {
    setFollowUpAction({ ...followUpAction, 'addVisit': event.target.checked });
  }
  const [followUpActions, setFollowUpActions] = useState([]);
  async function getFollowUpActionFromApi(visitId) {
    if (visitId !== undefined) {
      setFollowUpAction({ ...followUpAction, 'visitId': visitId });
      const response = await getFollowUpAction(visitId);
      console.log("Response :", response.data.data);
      setFollowUpActions(response.data.data);
      setFollowUpAction({
        actionDetails: '',
        actionDescription: '',
        actionDueDate: '',
        addVisit: false,
        visitId : visitId
      });
    }
  }

  useEffect(() => {
    async function getFollowUpActionFromApi(visitId) {
      if (visitId !== undefined) {
        setFollowUpAction({ ...followUpAction, 'visitId': visitId });
        const response = await getFollowUpAction(visitId);
        console.log("Response :", response.data.data);
        setFollowUpActions(response.data.data);
      }
    }

    getFollowUpActionFromApi(visitId);
  }, [visitId])

  const [reset, setReset] = useState(false)
  useEffect(() => {
    async function getFollowUpActionFromApi(visitId) {
      if (visitId !== undefined) {
        setFollowUpAction({ ...followUpAction, 'visitId': visitId });
        const response = await getFollowUpAction(visitId);
        console.log("Response :", response.data.data);
        setFollowUpActions(response.data.data);
      }
    }

    getFollowUpActionFromApi(visitId);
  }, [reset])


  const status = [
    { 0: "Pending" },
    { 1: "Completed" },
    { 2: "Action Needed" }
  ]

  const [followUpData, setfollowUpData] = useState(
    {
      "_id": "",
      "visitId": "",
      "actionDueDate": "",
      "actionStatus": -1,
      "status": "",
      "__v": "",
      "actionDescription": "",
      "actionDetails": ""
    }
  );

  const [invalidMessage, setInvalidMessage] = useState({
    "actionDueDate": "",
    "actionStatus": "",
    "status": "",
    "__v": "",
    "actionDescription": "",
    "actionDetails": ""
  })


  const [editModalIsOpen, setEditOpenModal] = useState(false)


  function openEditModal() {
    setEditOpenModal(true);
  }


  async function romoveFollowUpActionEvent(id) {
    const response = await removeFollowUpAction(id);
    console.log(response);
  }


  function validateFormData(formData) {
    var canSubmit = true;
    console.log("console form data ", formData);

    let tempInvalidMessage = invalidMessage
    if (formData["actionDetails"] === "" || formData["actionDetails"] === undefined) {
      tempInvalidMessage = { ...tempInvalidMessage, "actionDetails": "Please enter  action details" };
      canSubmit = false;
    } else {
      tempInvalidMessage = { ...tempInvalidMessage, "actionDetails": "" };
    }

    if (formData["actionDescription"] === "" || formData["actionDescription"] === undefined) {
      canSubmit = false;
      tempInvalidMessage = { ...tempInvalidMessage, "actionDescription": "Please enter  action description " };
    }
    else {
      tempInvalidMessage = { ...tempInvalidMessage, "actionDescription": "" };
    }


    if (formData["actionDueDate"] === "" || formData["actionDueDate"] === undefined) {
      tempInvalidMessage = { ...tempInvalidMessage, "actionDueDate": "Please select  Date  " };
      canSubmit = false;
    }
    else {
      tempInvalidMessage = { ...tempInvalidMessage, "actionDueDate": "" };
    }
    setInvalidMessage(tempInvalidMessage)


    if (formData["actionStatus"] === undefined || formData["actionStatus"] === undefined) {
      tempInvalidMessage = { ...tempInvalidMessage, "actionStatus": "Please select any status  " };
      canSubmit = false;
    }
    else {
      tempInvalidMessage = { ...tempInvalidMessage, "actionStatus": "" };
    }
    setInvalidMessage(tempInvalidMessage)
    console.log("canSubmit : ", canSubmit)
    return canSubmit

  }

  async function handleSubmit() {
    const canSubmit = validateFormData(followUpAction);
    if (canSubmit) {
      await addFollowUPAction(followUpAction);
      await getFollowUpActionFromApi(visitId);
      handleReset();
    }

  }
  return (
    <>


      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full bg-gray-800 bg-opacity-50 md:inset-0 h-full ${open ? 'flex' : 'hidden'}`}
      >

        <div className="relative p-4 w-full max-w-2xl max-h-full">

          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow border border-gray-300" >
            {/* Modal header */}
            <div>

            </div>
            <div>
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900">
                  Follow Up Actions
                </h3>
                <button
                  type="button"
                  className="text-gray-700 bg-transparent hover:bg-[#F7F6FE] hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:text-gray-900"
                  onClick={() => { handleReset(); onClose() }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}

              <Lottie
                animationData={animationData}
                loop={true}
                onLoopComplete={onAnimationCompleted}
                hidden={!submitTrue}
                style={{ width: 100, height: 400, margin: "auto" }}

              />
              <div hidden={submitTrue}>
                <div className='w-full p-5'>

                  {/* Name of the person */}
                  <div className="flex flex-col gap-2 px-4 mt-3">
                    <p className='text-lg ml-2'>Action Details</p>
                    <TextInput name={'actionDetails'} placeholder={'Action Details'} label={'Action Details'} type={'text'} onChange={handleTextChange} refv={inputRef} invalidMessage={invalidMessage.actionDetails} />
                  </div>
                  <div className="flex flex-col gap-2 px-4 mt-3">
                    <p className='text-lg ml-2'>Action Description</p>
                    <TextInput placeholder={'Action Description'} name={'actionDescription'} label={'Action Description'} type={'text'} onChange={handleTextChange} refv={descriptionRef} invalidMessage={invalidMessage.actionDescription} />
                  </div>
                  <div className="flex flex-col gap-2 px-4 mt-3">
                    <p className='text-lg ml-2'>Action Due Date</p>
                    <TextInput placeholder={'Action Due Date'} name={'actionDueDate'} label={'Action Due Date'} type={'date'} onChange={handleTextChange} refv={dateRef} invalidMessage={invalidMessage.actionDueDate} />
                  </div>
                  <div className="flex flex-col gap-2 px-4 mt-3">
                    <p className='text-lg ml-2'>Status</p>
                    <CustomSelectList items={status} name={'actionStatus'} onChange={handleListSelection} refv={statusRef} invalidMessage={invalidMessage.actionStatus} />
                  </div>
                  <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
                    <div class="flex items-center ">
                      <input id="" type="checkbox"
                        value=""
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={followUpAction.addVisit}
                        onChange={handleChangeOfCheckBox}

                      />
                      <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">Add Visit</label>
                    </div>
                  </div>
                </div>
                
                {/* bottom buttuns  */}
                <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button
                    type="button"
                    className="text-white bg-[#3dadd3] hover:bg-[#32B1DC] focus:ring-4 focus:outline-none focus:ring-[#32B1DC] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-200 focus:outline-none bg-[#E8453B] rounded-lg border  hover:bg-[#E8453B]  focus:z-10 focus:ring-4 focus:ring-[#E8453B]   dark:border-red-600 dark:hover:text-white"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* follow up action result */}
            {followUpActions.length !== 0 &&
              <div class="container mx-auto p-4">
                {/* <h1 class="text-2xl font-bold mb-4">Visit Action</h1> */}
                <div class="overflow-x-auto">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Details
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Due Date
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {
                        followUpActions.map((item, index) => (
                          <tr>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <div class="ml-4">
                                  <div class="text-sm font-medium text-gray-900">{item.actionDetails}</div>
                                  <div class="text-sm text-gray-500">{item.actionDescription}</div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">{new Date(item.actionDueDate).toDateString()}</div>
                              {/* <div class="text-sm text-gray-500">Optimization</div> */}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              {
                                item.actionStatus === 0 ?
                                  (<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-yellow-800">
                                    Pending
                                  </span>)
                                  : item.actionStatus === 1 ?
                                    (<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Completed
                                    </span>) : (<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                      Action Needed
                                    </span>)
                              }

                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-large flex align-middle">
                              <button onClick={() => { openEditModal(); setfollowUpData({ "visitId": item.visitId, "actionDueDate": item.actionDueDate, "actionDetails": item.actionDetails, "actionDescription": item.actionDescription, actionStatus: item.actionStatus, followUpActionId: item._id }) }} class="text-indigo-600 hover:text-indigo-900"><FaEdit size={"20"} /></button>
                              <button onClick={async () => { romoveFollowUpActionEvent(item._id); await getFollowUpActionFromApi(visitId); }} class="text-red-600 hover:text-indigo-900" ><MdCancel size={"20"} /></button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            }
            <EditModal open={editModalIsOpen} setter={setEditOpenModal} followUpData={followUpData} reset={reset} setReset={setReset} updatedata={getFollowUpActionFromApi} visitId={visitId} />
          </div>
        </div>
      </div>

    </>
  );
};

FollowUpActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FollowUpActionModal;
