import React, { useState, useEffect, useRef } from "react";
import VisitStatistics from "./VisitStatistics";
import UpcomingVisits from "./UpcomingVisits";
import {
  fetchAppointmentCounts,
  fetchVisitCounts,
} from "../api/get/homepagecardapi";

import AddVisitModal from "./visitLog/AddVisitModal";
import { AddVisit } from "../api/insert/Visit";
import {
  getAllVisitLogsFromDb,
  getAllVisitsCount,
  getVisitCountofOrgType,
} from "../api/get/visit";
import AddInstitutionModal from "./instLogs/AddInstModal";
import AddContPersonModal from "./visitLog/AddContPersonModal";
import Notification from "./common/Notification";
import ViewVisit from "./common/ViewVisit";
import UpcommingCard from "./common/UpcommingCard";
import PiCharts from "./common/PiCharts";
import BarChart from "./common/BarChart";

const Homepage = () => {
  const [appointmentCounts, setAppointmentCounts] = useState({
    pastVisitsCount: 0,
    futureVisitsCount: 0,
  });
  const [visitCounts, setVisitCounts] = useState({
    counts: 0,
  });
  const [isModalOpen, setModalOpen] = useState(false); // Move state declaration here
  const [counts, setCounts] = useState({});
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenAddInstModal = () => {
    console.info("ope handle modal");
    setOpenInstModal(true);
  };

  const orgSelected = useRef(null);
  const handleOpenAddPersonModal = (orgId) => {
    setOpenAddPersonModal(true);
    orgSelected.current = orgId;
  };

  const addNewVisitData = async (data) => {
    console.log("visitlogs", data);
    const newvisit = await AddVisit(data);
    console.log(newvisit);
    if (newvisit.data !== undefined) {
      if (newvisit.data.success) setModalOpen(false);
    }
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const counts = await fetchAppointmentCounts();
        setAppointmentCounts(counts);
      } catch (error) {
        console.error("Error fetching appointment counts:", error);
      }
    };
    getAllVisitCountDetails();
    fetchAppointments();
  }, []);

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const counts = await fetchVisitCounts(); //data fetched form vfetchVisitCounts
        setVisitCounts(counts);
      } catch (error) {
        console.error("Error fetching visit counts:", error);
      }
    };
    fetchVisits();
    getCountBasedonOrgType();
  }, []);

  const getAllVisitCountDetails = async () => {
    const visit = await getAllVisitsCount();
    console.log("visitCount -------", visit);
    if (visit.data) {
      setCounts(visit.data);
    }
  };

  const [orgTypeCount, stOrgTypeCount] = useState([]);
  // get the visit statistics based on org type
  const getCountBasedonOrgType = async () => {
    const orgCountType = await getVisitCountofOrgType();
    console.log("-------org----type--count", orgCountType);
    if (orgCountType.data) {
      stOrgTypeCount(orgCountType.data);
    }
  };

  const [openInstModel, setOpenInstModal] = useState(false);
  const [openAddPersonModal, setOpenAddPersonModal] = useState(false);

  // handle close the add contact person modal
  const handleCloseAddPersonModal = () => {
    setOpenAddPersonModal(false);
  };

  const handleCloseInstModal = () => {
    setOpenInstModal(false);
  };

  const [showVisitModal, setShowVisitModal] = useState(false);
  const [viewId, setViewId] = useState(undefined);

  const [refresh, setRefresh] = useState(false);

  return (
    <main className="flex flex-col p-5 bg-gray-100 mt-16 sm:ml-64">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 lg:gap-4">
        <UpcommingCard
          image={
            <svg
              className="text-white w-full h-full"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          }
          title={"Total Visits"}
          data={counts.totalCount}
          text={"Total visits"}
        />

        <UpcommingCard
          image={
            <svg
              className="h-full w-full text-slate-100"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <circle cx="9" cy="7" r="4" />
              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
              <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
            </svg>
          }
          title={"Upcoming Visits"}
          data={counts.upcomingVisitCount}
          text={"Upcoming visits"}
        />

        <div className="w-full h-[200px] bg-white flex flex-col px-3 py-3 rounded-2xl shadow-lg ">
          <div>
            <h2 className="text-gray-600 text-[1rem]">{"Organization Statistics"}</h2>
          </div>
          <div className="flex flex-col items-center">
            <BarChart dataItems={orgTypeCount} />
          </div>
        </div>

        {/* Pi chart */}
        <div className="w-full h-[200px] bg-white flex flex-col px-3 py-3 rounded-2xl shadow-lg ">
          <div>
            <h2 className="text-gray-600 text-[1rem]">{"Visit View"}</h2>
          </div>
          <div className="flex flex-col  overflow-hidden">
            <PiCharts items={counts} />
          </div>
        </div>

        <div className=" flex flex-col gap-3 justify-center items-center">
          <button
            onClick={handleOpenModal}
            className="relative w-full rounded-lg bg-[#3465eb] hover:bg-[#1d4ed8] p-0.5 shadow-lg focus:outline-none"
          >
            <div className="flex items-center p-2 rounded-md">
              <div className="w-12 h-12 bg-[#194dd9] flex items-center justify-center rounded-full border-2 border-white shrink-0">
                <svg
                  className="text-white w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <div className="ml-4">
                <h1 className="font-bold text-l text-white">Add New Visit</h1>
                <p className="text-white text-sm">New Visits</p>
              </div>
            </div>
          </button>
          <Notification>
            <div className="relative  w-full cursor-pointer rounded-lg bg-gray-300 shadow-xl hover:bg-gray-400 p-0.5">
              <div className="flex items-center p-2 rounded-md">
                <div className="w-12 h-12 bg-gray-700 flex items-center justify-center rounded-full border-2 border-white shrink-0">
                  <svg
                    className="text-white w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h1 className="font-bold text-lg text-blue-800">
                    Notification
                  </h1>
                  <p className="text-blue-700 text-sm">
                    {visitCounts.notificationCount} Unread Notifications
                  </p>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>

      <div className="w-full mt-8 rounded-lg bg-white p-0.5 shadow-lg">
        <UpcomingVisits
          refresh={refresh}
          openInstModel={handleOpenAddInstModal}
          openAddPersonModal={handleOpenAddPersonModal}
          showModal={showVisitModal}
          setShowModal={setShowVisitModal}
          viewId={viewId}
          setViewId={setViewId}
        />
      </div>

      {/* Add Visit Modal */}
      {viewId && (
        <ViewVisit
          showModal={showVisitModal}
          setShowModal={setShowVisitModal}
          viewId={viewId}
        />
      )}

      <AddVisitModal
        refresh={refresh}
        setRefresh={setRefresh}
        open={isModalOpen}
        onClose={handleCloseModal}
        openInstModal={handleOpenAddInstModal}
        openAddPesonModal={handleOpenAddPersonModal}
        isInstModalOpen={openInstModel}
        isContactModalOpen={openAddPersonModal}
      />
      <AddInstitutionModal
        open={openInstModel}
        onClose={handleCloseInstModal}
        isInstModalOpen={openInstModel}
      />
      <AddContPersonModal
        open={openAddPersonModal}
        onClose={handleCloseAddPersonModal}
        orgSelect={orgSelected.current}
      />

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 pt-4 mt-8">
        <div className="col-span-1 lg:col-span-2">
          <div className="rounded-lg bg-white p-0.5 shadow-lg w-full">
            <VisitStatistics />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Homepage;
