import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';
import TextInputNormal from '../common/TextInputNormal';
import { ValidateEmail } from '../../validations/Validation';
import { passwordResetLinkTrigger } from '../../api/auth/Password';


const PasswordReset = ({ open, onClose , openNotification }) => {

  const [error , setError] = useState({
    email:'',
  })
const [email,setEmail] = useState('');

const handleEmailChange=async(e)=>{
  const {name, value} = e.target;
  setError({...error,email:''})
  const validate = await ValidateEmail(value);
  if(validate == false){
    setError({...error,email:'Invalid Email'})
  }
  setEmail({...email,[name]:value})
}

// password reset email trigger
const handlePasswordResetEmail=async(email)=>{
 if(!error.email){
  if(email!==''){
    const resetLink = await passwordResetLinkTrigger(email);
    if(resetLink.data){
      onClose();
      openNotification();
    }
    else{
      onClose();
      openNotification(true);
    }
  }
  else{
    setError({...error,email:'Required'})
  }
}
}
  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden={!open}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full ${open ? 'flex' : 'hidden'}`}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow border border-gray-300">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5  rounded-t">
            <h3 className="text-xl font-semibold text-[#2979E3]">
              Forgot Your Password
            </h3>
            <button
              type="button"
              className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-[white] dark:hover:text-gray-900"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className='w-full flex flex-col gap-5 px-10 pb-10'>
            <div className='flex flex-col gap-1'>
                <p className='text-gray-900'>Enter your email address</p>
                <input placeholder={"Email"}
                    type="text"
                    name='email'
                    onChange={handleEmailChange}
                    class="text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-700 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-[#2979E3] focus:outline-none   h-14"
                />
                 <p className="text-[#f72743] ml-2 font-serif text-[11px] ">{error.email}</p>
            </div>
            <div>
                <button onClick={()=>{handlePasswordResetEmail(email)}} className='text-white w-full bg-[#1A73E8] hover:bg-[#1A73E8] font-bold rounded-lg text-md  py-2.5 text-center'>
                    Next
                </button>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PasswordReset;
