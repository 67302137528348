import React, { useEffect, useRef, useState } from 'react'
import InstLogsCard from '../components/instLogs/InstLogsCard';
import { CSVLink } from 'react-csv';

import DropDownlist from '../components/common/DropDownlist';
import CustomSelectList from '../components/common/CustomSelectList';
import InstitutionsLogsTable from '../components/instLogs/InstLogsTable';
import AddVisitModal from '../components/visitLog/AddVisitModal';
import AddInstitutionModal from '../components/instLogs/AddInstModal';
import Sidebar from '../components/sidebar';
import Navbar from '../components/navbar';
import { getAllVisitLogsFromDb, getVisitEmployeeData } from '../api/get/visit';
import SearchableList from '../components/common/SearchableList';
import { getOrganization } from '../api/get/organization';
import CalenderComponent from '../components/instLogs/CalenderComponent';
import FollowUpActionModal from '../components/instLogs/FollowUpModal';
import { AddFollowUpAction } from '../api/insert/followUpAction';
import Popup from '../components/common/Popup';
import EditVisit from '../components/instLogs/EditVisit';
import MultiSelectDropdown from '../components/common/MultipleSelectList';
import AddContPersonModal from '../components/visitLog/AddContPersonModal';
import { AddVisit } from '../api/insert/Visit';
import AlertBox from '../components/AlertBox';
import NewVisitAlert from '../components/NewVisitAlert';
import NestedDropdownList from '../components/common/NestedDropdown';
import { fetchOrganizationTypes, fetchOrganizationsByType } from '../api/get/VisitStatisticsapi';

const InstitutionLogs = () => {
  const title = ["#", "Institution", "Visited Person","Contact Person", "Date", "Time", "Type", "Status", "Action"];
  
  const state = ['Kerala', 'Tamilnadu'];
  const district = ['Kannur', 'kasaragod'];
  const instType = ['Govt', 'Corporate', 'Academics'];
  const [visitLogs, setVisitLogs] = useState([]); //store all visit data
  const [employeeData, setEmployeeData] = useState([]); // store employee data
  const [totalVisitCount, setTotalVisitCount] = useState(0);
  const [vistCompleted, setVisitCompleted] = useState(0);
  const [visitPending, setVisitPending] = useState(0);
  const [visitFollowUpAction, setVisitFollowUpAction] = useState(0);
  const [organizationData, setOrganizationData] = useState([]);

  // controle the advance filter option
  const [advanceFilterOpen, setAdvanceFilterOpen] = useState(false);
  // controle the opening  and closing of follow up action modal
  const [openActionFollowupModal, setActionFollowupAcionModal] = useState(false);

  const [openVisitModal , setOpenVisitModal] = useState(false);//add visit modal opening and closing controled by this variable

  const [isInstModalOpen, setInstModalOpen] = useState(false); // variable for controling open and close of add institution modal
  const [openAddContactPerson , setOpenAddContactPerson] = useState(false); //add contact person
  const employeeId = useRef('');
  const organizationId = useRef('');
  const fromDate = useRef('');
  const toDate = useRef();
  const [departmentData , setDepartmentData] = useState([]);
  const orgType = useRef('');
  const orgTypeId = useRef('');
  const  [refresh , setRefresh] =  useState(false) ;
  useEffect(() => {
    getAllVistData();
    employeeDataFetchfromDb();
    // getOrganizationData();
    fetchTheOrganizationTypes();
  }, [refresh])

  // get all visit data 
  const getAllVistData = async () => {
    const visitData = await getAllVisitLogsFromDb();
    if (visitData.data) {
      const dataWidthOrderNo = visitData.data.map((visit , index) =>({
        ...visit,
        orderNo: index+1
      }))
      setVisitLogs(dataWidthOrderNo);
      const visitCounts = countVisits(visitData.data);
      setTotalVisitCount(visitCounts.total);
      setVisitCompleted(visitCounts.completed);
      setVisitPending(visitCounts.pending);
      setVisitFollowUpAction(visitCounts.followUpAction);
    }
    else {

    }
  }

  const countVisits = (visits) => {
    const counts = {
      total: visits.length,
      pending: 0,
      completed: 0,
      followUpAction:0
    };

    visits.forEach(visit => {
      if (visit.visitStatus === 'Pending') {
        counts.pending += 1;
      } else if (visit.visitStatus === 'Completed') {
        counts.completed += 1;
      } else if (visit.visitStatus === 'Action Needed') {
        counts.followUpAction += 1;
      }
    });

    return counts;
  };

  

  //get employee data 
  const employeeDataFetchfromDb = async () => {
    const empdata = await getVisitEmployeeData();
    if (empdata.data) {
      const resultdata = empdata.data.map(item => {
        return {
          [item.userId._id]: item.userId.fullName
        }
      })
      console.log(resultdata);
      setEmployeeData(resultdata);
    }
    else {

    }
  }

  // handle the search of visitlist of employee
  const handleEmployeeSelect = async (employeeIdArray) => {
   if(employeeIdArray.length === 0){
    employeeId.current = '';
   }
   else{
    employeeId.current = employeeIdArray;
   }
    
    filterTheVisitData();
}
// open add institusion modal
  const handleOpenInsModal = () => {
    setInstModalOpen(true);
  }
// close add institution modal
  const handleCloseInstModal = () => {
    setInstModalOpen(false);
}

const orgSelected = useRef(null); // organization selected during add visit
// open add contact person modal
const handleopenAddContactPersonModal =(orgId)=>{
  setOpenAddContactPerson(true);
  orgSelected.current = orgId;
}
// close add contact person modal
const handleCloseAddContactPerson=()=>{
  setOpenAddContactPerson(false);
}

  // fetch all organization data
  const getOrganizationData = async () => {
    const orgData = await getOrganization();
    if (orgData.data) {
      const orgMap = orgData.data.organizations.map(org => ({
        value: org._id,
        label: org.organizationName
      }))
      setOrganizationData(orgMap)
    }
    else {
      setOrganizationData([])
    }
  }

// filter the table data based on the organization
  const handleSelectList = async (data) => {

  organizationId.current = data.value;
  
  filterTheVisitData();
  }

  // filter based on from date and to date
  const handleDateFilter = async (date) => {
    fromDate.current = date.fromDate;
    toDate.current = date.toDate;
   
    filterTheVisitData();
  }

  // controle the advance filter options
  const handleControleVisibility = () => {
    setAdvanceFilterOpen(!advanceFilterOpen)
  }
  //set visit 
  const [visit, setVisit] = useState();
  // handle the opening of follow up action modal
  const openFollowUpActionModal = (visitId) => {
    setActionFollowupAcionModal(true);
    setVisit(visitId);
  }

  // handle the closing of follow up action modal
  const closeFollowUpActionModal = () => {
    setActionFollowupAcionModal(false);
  }

  const [actionSubmitted, setActionSubmitted] = useState(false)
  // add the new follow up action
  const addFollowUpAction = async (data) => {
    const response = await AddFollowUpAction(data)
    console.log(response)
    setActionSubmitted(true);

  }
// animation
const animationCompleted=()=>{
  setActionSubmitted(false)
}

// add new vist data is get from the addVisit modal
const addNewVisitData =async (data)=>{
  const newvisit =await AddVisit(data);
  console.log(newvisit);
  if (newvisit.data !==  undefined)
 {if (newvisit.data.success ) setModalOpen(false);}
}

// csv header
const csvHeader = [
  { label: "Order No", key: "orderNo" },
  { label: "Institution", key: "institution" },
  { label: "Visited Person", key: "visitedPerson" },
  { label: "Purpose", key: "purpose" },
  { label: "Contact Person", key: "contactPerson" },
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Type", key: "type" },
  { label: "Status", key: "status" },
  { label: "Action", key: "action" }
];
// csv table body
const csvData = visitLogs.map((item,index)=>({
    orderNo: index + 1,
    institution: item.contactPersonId.organizationId.organizationName,
    visitedPerson: item.employeeId.userId.fullName,
    purpose: item.purpose,
    contactPerson: item.contactPersonId.name,
    date: item.visitDateTime,
    time: item.visitDateTime,
    type: item.contactPersonId.organizationId.organizationType.name,
    status: item.visitStatus,
    action: "Follow Up"
}))

  const [isModalOpen, setModalOpen] = useState(false);

  const [editModelData, setEditDataModel] = useState({

    visitId: "",
    institution: '',
    visitDateTime: '',
    purpose: '',
    contactPersonId: '',
    additionalNotes: '',
    file: '',
    visitType: 1,
    employeeId: "",
    discussionTopic: "",
   

  })


  function setFile(file) { 
    setEditDataModel({...editModelData , file })
  }


  const handleOpenModal = ( data ) => {
    console.log("vimal")
    setModalOpen(true);
    console.log("modal data",data);
    setEditDataModel({
      visitId: data.visitId,
      institution: data.institution,
      visitDateTime: data.visitDateTime,
      purpose: data.purpose,
      contactPersonId: data.contactPersonId,
      additionalNotes: data.additionalNotes,
      file: data.file,
      visitType: 1,
      employeeId: data.employeeId,
      discussionTopic: data.discussionTopic,
      visitStatus :  data.visitStatus,
    })

  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

// refresh the page
const handleRefresh=()=>{
  window.location.reload();
}

// handle close visit modal
const handleCloseVisitModal =()=>{
  setOpenVisitModal(false);
}

const handleOpenVisitModal=()=>{
  setOpenVisitModal(true)
}

// fetch the organization types 
const fetchTheOrganizationTypes =async()=>{
  const department = await fetchOrganizationTypes();
  console.log("derpartmengt list------------",department) 
  const orgtype = department.map(org => ({
    value: org._id,
    label: org.name
  }))
  setDepartmentData(orgtype);
}

// function to handle the org by the type 
const handleDepartmentSelect=async(e)=>{
  orgTypeId.current = e.value;
  orgType.current = e.label;
  console.log("selected org----",orgType.current);
  const organisation = await fetchOrganizationsByType(orgType.current);
  const org = organisation.map(org => ({
    value: org._id,
    label: org.organizationName
  }))
  console.log("list--",organisation)
  setOrganizationData(org);
  filterTheVisitData();
}

const filterTheVisitData=async()=>{
  const filterData = await getAllVisitLogsFromDb(fromDate.current, toDate.current, organizationId.current,employeeId.current,orgTypeId.current);
  if (filterData.data) {
    const dataWidthOrderNo = filterData.data.map((visit , index) =>({
      ...visit,
      orderNo: index+1
    }))
    setVisitLogs(dataWidthOrderNo);
    const visitCounts = countVisits(filterData.data);
    setTotalVisitCount(visitCounts.total);
    setVisitCompleted(visitCounts.completed);
    setVisitPending(visitCounts.pending);
    setVisitFollowUpAction(visitCounts.followUpAction);
  }
  else {

  }
}

const college = ['College of Engineering Munnar','MIT','CET','CEV'];




  return (
    <>
      <Navbar title={'Visit Logs'} />
      <Sidebar />
      <div className='flex sm:ml-[240px] pt-[65px] flex-col items-center justify-center gap-5  w-auto '>
        {/* <Sidebar /> */}

        {/* Top cards for visit status of college */}
        <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 2xl:gap-12 w-full lg:w-11/12 p-6 sm:p-4'>
          <InstLogsCard bgColor={'bg-[#3C80F7]'} imgbgColor={'bg-[#82acf5]'} title={totalVisitCount} text={'Visits'} />
          <InstLogsCard bgColor={'bg-[#589D56]'} imgbgColor={'bg-[#74AF78]'} title={vistCompleted} text={'Completed'} />
          <InstLogsCard bgColor={'bg-[#E8C23B]'} imgbgColor={'bg-[#E8CC62]'} title={visitPending} text={'Pending'} />
          <InstLogsCard bgColor={'bg-[#E8453B]'} imgbgColor={'bg-[#E86862]'} title={visitFollowUpAction} text={'Action'} />
        </div>
        <div className='flex flex-row w-full gap-2 justify-center sm:justify-between'>
          <div className='flex  flex-col lg:flex-row gap-3 pl-5 lg:items-center'>
            <div className=' gap-3 items-center hidden'>
              <p className='text-[15px] font-medium'>Show</p>
              <DropDownlist />
            </div>
            {/* This select list is hidden for mobile device */}
            <div className='ml-2 max-w-[300px] hidden sm:flex sm:flex-row sm:items-center gap-2'>
              <p className='text-gray-600  md:inline-block'>Employee</p>
              {/* <CustomSelectList items={employeeData} name={'employee'} onChange={handleEmployeeSelect} /> */}
              <MultiSelectDropdown name={'employee'} items={employeeData} handleSelect={handleEmployeeSelect} />
                <button onClick={handleRefresh} class="flex items-center px-2 py-2 font-medium tracking-wide text-gray-700  transition-colors duration-300 transform rounded-lg hover:bg-gray-300 focus:outline-none">
                  <svg class="w-5 h-5 mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                  </svg>
                </button>
            </div>
            <button onClick={handleControleVisibility} className='bg-[#624DE3] hidden sm:inline-block rounded-md text-[12px]  text-white font-bold px-2 py-2 w-[100px] h-[30px]'>{advanceFilterOpen === true ? 'Close' : 'Advance filter'}</button>
          </div>

          <div className='flex pr-5'>
            <div className='flex flex-row gap-2 w-full justify-center items-end xl:items-center'>
              {/* csv export */}
                <CSVLink
                    data={csvData}
                    headers={csvHeader}
                    filename={"visitLogs-visits.csv"}
                    className="flex justify-center items-center text-nowrap bg-transparent hover:bg-[#624DE3] text-[#624DE3] font-semibold hover:text-white py-2 px-2  border border-[#624DE3] hover:border-transparent rounded  sm:w-[110px]  h-[40px] w-auto text-[10px] sm:text-[15px] mr-2"
                  >
                  Export CSV
                </CSVLink>
              <button onClick={handleOpenInsModal} type="button" class="flex  items-center gap-2 text-white bg-[#624DE3] hover:bg-[#624DE3] focus:ring-4 focus:ring-[#a79af8] rounded-lg text-[10px] md:text-sm px-2 md:px-5 py-2.5 me-2 mb- focus:outline-none dark:focus:ring-[#624DE3] h-[40px] w-[100px] md:w-[180px] font-semibold ">
                <img src={require('../assets/plusIcon.svg').default} alt='' className='w-[13px] hidden sm:inline sm:w-auto' />
                Add Institutions
              </button>
              <button onClick={handleOpenVisitModal} type="button" class="flex justify-center  items-center gap-2 text-white bg-[#624DE3] hover:bg-[#624DE3] focus:ring-4 focus:ring-[#a79af8] rounded-lg text-[10px] md:text-sm px-2 md:px-5 py-2.5 me-2 mb- focus:outline-none dark:focus:ring-[#624DE3] h-[40px] w-[100px] md:w-[130px] font-semibold ">
                <img src={require('../assets/plusIcon.svg').default} alt='' className='w-[13px] hidden sm:inline sm:w-auto' />
                  Add Visit
              </button>
            </div>
          </div>

        </div>

        {/* dropdown list of colleges only for mobile device */}
        <div className='w-full flex flex-row items-center gap-3 px-2 sm:hidden'>
          {/* <div className='flex flex-col bg-red-300'> */}
          <p>Employee</p>
          <MultiSelectDropdown name={'employee'} items={employeeData} handleSelect={handleEmployeeSelect} />
                <button onClick={handleRefresh} class="hidden sm:flex items-center px-2 py-2 font-medium tracking-wide text-gray-700  transition-colors duration-300 transform rounded-lg hover:bg-gray-300 focus:outline-none">
                  <svg class="w-5 h-5 mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                  </svg>
                </button>
          {/* </div> */}
          <button onClick={handleControleVisibility} className='bg-[#3C80F7] text-nowrap inline-block sm:hidden rounded-md text-[10px]  text-white font-bold px-2 py-2 w-[100px] h-[30px]'>{advanceFilterOpen === true ? 'Close' : 'Advance filter'}</button>
        </div>


        {/* Advance filtering data by date and organizations*/}
        {advanceFilterOpen &&
          <div className='w-full flex flex-col  gap-3 2xl:grid grid-cols-2 sm:gap-3 px-2 2xl:px-5'>
            <div className='flex md:items-center gap-2 flex-col md:flex-row'>
              <div className='flex flex-col items-start  lg:flex-row min-w-[300px] gap-2 justify-start lg:items-center'>
                <p className='font-medium text-nowrap'>Organization Type</p>
                <SearchableList options={departmentData} handleChange={handleDepartmentSelect}  />
                {/* <SearchableList options={organizationData} handleChange={handleSelectList}  /> */}
                {/* <NestedDropdownList/> */}
              </div>
            <div className='flex flex-col items-start  lg:flex-row min-w-[300px] gap-2 justify-start lg:items-center'>
                <p className='font-medium text-nowrap'>Organization List</p>
                <SearchableList options={organizationData} handleChange={handleSelectList}  />
                {/* <NestedDropdownList/> */}
              </div>
            </div>
            <div className='flex gap-2   flex-col items-start lg:flex-row lg:items-center 2xl:justify-end'>
            <p className='font-medium'>Date</p>
              <CalenderComponent handleSubmit={handleDateFilter} />
            </div>
          </div>
        }
        {/* Table view */}
        <div className="w-full pb-6 px-2 sm:px-7 mt-5">
          <InstitutionsLogsTable title={title} content={visitLogs} handleOpenFollowUPModal={openFollowUpActionModal} handleOpenModal={handleOpenModal} updateFunction={getAllVistData}/>
        </div>

        <EditVisit updateData={getAllVistData} open={isModalOpen} visitId={editModelData.visitId} onClose={handleCloseModal} institution={editModelData.institution} visitDateTime={editModelData.visitDateTime} purpose={editModelData.purpose} employeeId={editModelData.employeeId} contactPersonId={editModelData.contactPersonId} file={editModelData.file} additionalNotes={editModelData.additionalNotes} discussionTopic={editModelData.discussionTopic}  visitStatus={editModelData.visitStatus} openAddPesonModal={handleopenAddContactPersonModal} openInstModal={handleOpenInsModal} setFile={setFile} isUpComing={false}/>
      
        {/*Follow up action modal */}
        <FollowUpActionModal open={openActionFollowupModal} onClose={closeFollowUpActionModal} addFollowUPAction={addFollowUpAction} visitId={visit} submitTrue={actionSubmitted} onAnimationCompleted={animationCompleted} />
         
        <AddVisitModal refresh={refresh} setRefresh={setRefresh} isContactModalOpen={openAddContactPerson}  isInstModalOpen={isInstModalOpen} open={openVisitModal} onClose={handleCloseVisitModal} institutionList={college} openInstModal={handleOpenInsModal} openAddPesonModal={handleopenAddContactPersonModal}   />
         {/* modal for adding institution */}
         <AddInstitutionModal open={isInstModalOpen} onClose={handleCloseInstModal} instType={instType} state={state} district={district} />
        <AddContPersonModal open={openAddContactPerson} onClose={handleCloseAddContactPerson} isInstModalOpen={isInstModalOpen} orgSelect={orgSelected.current}/>
    </div>
    
    </>
  )
}

export default InstitutionLogs;
