import React from 'react';
import PropTypes from 'prop-types';
import CustomSelectList from '../../components/common/CustomSelectList';
import TextInput from '../../components/common/TextInput';



const UserRegModal = ({ open, onClose}) => {
   const handleChangeText =(e)=>{
    const {name , value} = e.target;
    console.log(name , "value",value);
  }
  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden={!open}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full ${open ? 'flex' : 'hidden'}`}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-2xl font-semibold text-[#1fbcf0]">
             Register
            </h3>
            <button
              type="button"
              className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-[#60c1e2] dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          
        <div className='w-full px-5 py-5'>
          
          <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Full Name</p>
                <TextInput placeholder={'Full Name'} label={'Full Name'} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Email</p>
                <TextInput placeholder={'Email'} label={'Email'} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Mobile Number</p>
                <TextInput placeholder={'Mobile Number'} label={'Mobile Number'} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Employee Id</p>
                <TextInput placeholder={'Employee Id'} label={'Employee Id'} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Department</p>
                <CustomSelectList items={['Solutions','Academics','KO']} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Designation</p>
                <CustomSelectList items={['Manager','Associates','Head']} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Password</p>
                {/* <PasswordInput placeholder={'Password'}  /> */}
                <TextInput placeholder={'Password'} label={'Password'} type={'password'} />
            </div>
            <div className='flex flex-col px-2 gap-2 mt-3'>
                <p className='text-lg text-gray-800 font-sans'>Confirm Password</p>
                <TextInput placeholder={'Confirm Password'} label={'Confirm Password'} type={'password'} />
            </div>
        </div>
        

          {/* bottom buttuns  */}
          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              type="button"
              className="text-white bg-[#40b1d7] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center  dark:hover:bg-[#11a7d9] dark:focus:ring-[#32B1DC]"
              onClick={onClose}
            >
              Submit
            </button>
            <button
              type="button"
              className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UserRegModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserRegModal;
