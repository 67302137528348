import { FaFileLines, FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileCsv } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";

function FileBadge({ name, filename , handleRemove , originalname , path,hideClose}) {
    // Define a mapping for file extensions to their respective styles and icons
    const fileTypeMap = {
        pdf: { color: 'bg-red-200 text-red-600 hover:bg-red-300', icon: FaFilePdf },
        doc: { color: 'bg-blue-200 text-blue-600 hover:bg-blue-300', icon: FaFileWord },
        docx: { color: 'bg-blue-200 text-blue-600 hover:bg-blue-300', icon: FaFileWord },
        xls: { color: 'bg-green-200 text-green-600 hover:bg-green-300', icon: FaFileExcel },
        xlsx: { color: 'bg-green-200 text-green-600 hover:bg-green-300', icon: FaFileExcel },
        csv: { color: 'bg-green-200 text-green-600 hover:bg-green-300', icon: FaFileCsv },
        jpg: { color: 'bg-purple-200 text-purple-600 hover:bg-purple-300', icon: FaFileImage },
        jpeg: { color: 'bg-purple-200 text-purple-600 hover:bg-purple-300', icon: FaFileImage },
        png: { color: 'bg-purple-200 text-purple-600 hover:bg-purple-300', icon: FaFileImage },
        default: { color: 'bg-gray-200 text-gray-600 hover:bg-gray-300', icon: FaFileLines }
    };

    // Get the file extension from the filename
    var extension = undefined 
    try {
        extension = filename.split('.').pop().toLowerCase();
    }
    catch{
        extension = originalname.split('.').pop().toLowerCase();
    }

    // Get the styles and icon based on the file extension
    const { color, icon: Icon } = fileTypeMap[extension] || fileTypeMap['default'];

    return (
        <span className={`inline-flex items-center m-1 py-1 px-2 rounded-full text-sm font-semibold ${color}`}>
            <Icon />
            <button onClick={()=>{console.log("this is here"+path);window.open("/"+path)}}>
            <span className="ml-1 text-nowrap">
                {name}
            </span>
            </button>
            <button className={`${hideClose && hideClose === true ?'hidden':'block'}`} onClick={handleRemove}>
                <span className="hover:bg-gray-200 hover:text-gray-600">
                <IoCloseCircleOutline size={20} />
                </span>
            
                </button>
        </span>
    );
}

export default FileBadge;
