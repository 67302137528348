import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

// add new Contact person
export const removeVisit = async(id)=>{
    try{
        var data = prompt("Please enter the reason for deletion");
        while (data === ""){
            alert("Cannot delete without the reason");
            data = prompt("Please enter the reason for deletion");
        }
        if (data!==null &&data !== ""){
            const newPerson =  await axios.post(`${url}/api/associates/visit/remove`,{visitId  :  id , deleteReason : data},{
                headers: {
                    'Authorization': localStorage.getItem("token")
                }
            })
            return newPerson;
        }
        
        
    }
    catch(error){
        console.error(error);
        return error;
    }
}