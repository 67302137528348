
import PropTypes from 'prop-types';

const MessageSuccess = ({ open, onClose }) => {

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden={!open}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  md:inset-0 h-[calc(100%-1rem)] max-h-full ${open ? 'flex' : 'hidden'}`}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow border border-gray-300">
         
          {/* Modal body */}
          <div className='w-full flex flex-col items-center gap-2 px-2 py-5'>
            <div>
                <img src={require('../../assets/success-green.webp')} className=' object-contain w-[40px] sm:w-[70px]' alt='' />
            </div>
            <div className='flex flex-col justify-center items-center'>
                {/* <h3 className='text-[27px] font-bold text-[#01A601]'>Info!</h3> */}
                <p className='text-[15px] sm:text-[22px] text-center text-[#1a3a6d] font-[600]'>If your email is registered with us, you will receive a password reset link shortly.</p>
            </div>
            <div>
                <button onClick={onClose} className='bg-[#01A601] cursor-pointer hover:bg-[#018201] active:bg-[#018201] text-white px-2 py-1 sm:px-5 sm:py-2 rounded-lg font-semibold'>Close</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

MessageSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MessageSuccess;
