import animation from "../animation/success.json";
import AlertBox from "./AlertBox";



function CommonAlert({ isOpen, setOpen , alertMessage }) {
    return (
    <>
            <AlertBox isOpen={isOpen} setOpen={setOpen} alertMessage={alertMessage} buttonTheme={" bg-green-600 hover:ring-green-300 "} animation={animation}/>
    </>)
}


export default CommonAlert;