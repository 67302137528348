import axios from "axios"
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function checkAdmin() {
    
    try {
        
        const response = await axios.post(`${url}/api/admin/check`, {

        }, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        return response.data.isAdmin;
    }
    catch (error) {
        console.log(error);
    }
}