import { useEffect } from "react";

const VisitDuplication = ({ open, onClose,message,canProceed,handleSubmit}) => {
  
useEffect(()=>{

},[])
    return (
      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 backdrop-blur-sm h-full ${open ? 'flex' : 'hidden'}`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow border border-gray-300">
           
            {/* Modal body */}
            <div className='w-full flex flex-col items-center gap-5 px-2 py-5'>
              
              <div className='flex flex-col justify-center items-center'>
                  <div className="flex justify-center items-center">
                    <img src={require('../../assets/warningImg.svg').default} alt="fdgd" className="bg-white w-[60px]" />
                  </div>
                  <h2 className="text-[30px] font-semibold">Warning!!</h2>
                  {/* <p>If you want to conduct the visit on the same day, please click 'proceed'.</p> */}
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className='text-[20px] text-[#353536] font-[400] text-center'>{message}</p>
              </div>
              {canProceed ===true?
              <div className="flex gap-3">
                  <button onClick={handleSubmit}  className='bg-[#2563EB] cursor-pointer hover:bg-[#1657e3] active:bg-[#1657e3] text-gray-100 hover:text-white px-2 py-1 sm:px-5 sm:py-2 rounded-lg font-semibold'>Proceed</button>
                  <button onClick={onClose} className='bg-[#2e2f2e] cursor-pointer hover:bg-[#2f302f] active:bg-[#323332] text-gray-200 hover:text-white active:text-white px-2 py-1 sm:px-5 sm:py-2 rounded-lg font-semibold'>Cancel</button>
              </div>
              :
              <div className="flex items-center justify-center">
              <button onClick={onClose}  className='bg-[#2563EB] cursor-pointer hover:bg-[#1657e3] active:bg-[#1657e3] text-gray-100 hover:text-white px-2 py-1 sm:px-5 sm:py-2 rounded-lg font-semibold'>Close</button>
              </div>
}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  
  
  export default VisitDuplication ;
  