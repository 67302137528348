import React, { useState } from 'react';


const Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

  return (
    <>
    {/* <div className=" relative min-w-screen sm:ml-72 mt-10"> */}
        
    <div className='md:hidden min-w-screen max-h-[100px] absolute top-0 flex items-center pl-4 bg-white h-[70px]'>
    <div>
    <button onClick={toggleSidebar} type="button" class="h-[40px] w-[40px] p-1 bg-white rounded-md border-2 border-gray-500">
        {
            !isSidebarOpen?(
                <img src={require('../../assets/menu.png')} alt=''    className="object-cover w-full h-full" />
               
            ):
            (
                <img src={require('../../assets/close.png')} alt=''    className="object-cover w-full h-full" /> 
            )
        }
        </button>
    </div>
    </div>

    {isSidebarOpen && (
      <div className="sidebar flex flex-col justify-between items-start absolute top-[70px] md:top-0 bottom-0 lg:left-0 p-2 w-[300px] overflow-y-auto text-center bg-[#fff] border-r-2 border-gray-200">
       
       <div className='flex flex-col w-full'>
       <div className="text-gray-100 text-xl">
          <div className="p-2.5 mt-1 flex items-center">
            <i className="bi bi-app-indicator px-2 py-1 rounded-md bg-blue-600"></i>
            <h1  onClick={toggleSidebar} className="font-bold text-gray-700 text-[25px] ml-3">Visit App</h1>
           
          </div>
          <div className="my-2 bg-gray-600 h-[1px]"></div>
        </div>
        {/* <div className="p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer bg-[#32B1DC] text-white">
          <i className="bi bi-search text-sm"></i>
          <input
            type="text"
            placeholder="Search"
            className="text-[15px] ml-4 w-full bg-transparent focus:outline-none"
          />
        </div> */}
         <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#32B1DC] hover:text-white">
          <i className="bi bi-bookmark-fill"></i>
          <img src={require('../../assets/dashboard.png')} alt=''    className="object-cover w-[20px] h-[20px]" /> 
          <span className="text-[15px] ml-4 font-bold">Dashboard</span>
        </div>

        <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#32B1DC] hover:text-white">
          <i className="bi bi-bookmark-fill"></i>
          <img src={require('../../assets/school.png')} alt=''    className="object-cover w-[20px] h-[20px]" /> 
          <span className="text-[15px] ml-4 font-bold">Visit logs</span>
        </div>

        <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#32B1DC] hover:text-white">
          <i className="bi bi-bookmark-fill"></i>
          <img src={require('../../assets/school.png')} alt=''    className="object-cover w-[20px] h-[20px]" /> 
          <span className="text-[15px] ml-4 font-bold">Institutions logs</span>
        </div>


        <div className="my-4 bg-gray-600 h-[1px]"></div>
        {/* <div
          className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
          onClick={toggleDropdown}
        >
          <i className="bi bi-chat-left-text-fill"></i>
          <div className="flex justify-between w-full items-center">
            <span className="text-[15px] ml-4 text-gray-700 font-bold">Chatbox</span>
            <span className={`text-sm ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`} id="arrow">
              <i className="bi bi-chevron-down"></i>
            </span>
          </div>
        </div> */}
        {/* {isDropdownOpen && (
          <div
            className="text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold"
            id="submenu"
          >
            <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1">Social</h1>
            <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1">Personal</h1>
            <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1">Friends</h1>
          </div>
        )} */}
       </div>
       
       {/* bottom */}
        <div className='flex w-full'>
            <div className="p-2.5 w-full mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#32B1DC] hover:text-white">
            <i className="bi bi-box-arrow-in-right"></i>
            <span className="text-[15px] ml-4 font-bold">Logout</span>
            </div>
        </div>
       
      </div>
    )}
  {/* </div> */}


  </>
  )
}

export default Sidebar;
