import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';
import CustomSelectList from '../common/CustomSelectList';
import { getOrgType } from '../../api/get/institutionType';
import { getOrganization } from '../../api/get/organization';
import { addOrganization } from '../../api/insert/organaization';
import CommonAlert from '../CommomAlert';
// Toast notification 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ValidateEmail, validateInstitution,validatePlaceText } from '../../validations/Validation';

const AddInstitutionModal = ({ open, onClose, instType, }) => {


  const [institutionType, setInstitutionType] = useState([]);

  const [institutionList, seInstitutionList] = useState({
    organizationName: '',
    place: '',
    email:'',
    organizationType: '',
    district: '',
    state: '',
    longitude: "45.2559",
    latitude: "85.2369",
  });

  const [ error, setError] = useState({
    organizationName: '',
    place: '',
    email:'',
    organizationType: '',
    district: '',
    state: '',
    // longitude : "45.2559" ,
    // latitude : "85.2369" ,
  })



  useEffect(() => {
    getType()
  }, [])

  // handle text change function
  const hadleTextChange = (e) => {
    const { name, value } = e.target;
    setError({ ...error, [name]:''})
    const trimmedValue = value.trim();
    console.log(trimmedValue);

    if (trimmedValue === '') {
      setError({ ...error, [name]: 'Cannot be empty or contain only spaces' });
    } else {
      seInstitutionList({ ...institutionList, [name]: value })
    }



  }
  // handle the selected data of the list
  const handleChangeOfList = (e) => {
    const { name, value } = e.target;
    setError({ ...error, [name]: '' })
    seInstitutionList({ ...institutionList, [name]: value })
  }

  // fetch the type of organization
  const getType = async () => {
    const response = await getOrgType();
    var types = [];
    var out = [];
    types = response.data.types;
    types.forEach(element => {
      var ob = {};
      ob[element._id] = element.name;
      out.push(ob);
    });
    setInstitutionType(out)
  }

  // add institution
const instRef = useRef(null);
const stateRef = useRef(null);
const distRef = useRef(null);
const instNameRef = useRef(null);
const instPlaceRef = useRef(null);
const instEmailRef = useRef(null);
   
  const handleAddInstitution = async () => {

    const validateEmail  = ValidateEmail(institutionList.email);
    const validateOrgName = validateInstitution(institutionList.organizationName);
    const validatePlace = validatePlaceText(institutionList.place);
    
   console.log(validateOrgName,validatePlace)
    if (institutionList.organizationType === '') {
      setError({ ...error, 'organizationType': 'Required' })
    }
    else if ((institutionList.organizationName) === '') {
      setError({ ...error, 'organizationName': 'Required' })
    }
    else if (institutionList.email === '') {
      setError({ ...error, 'email': 'Required'})
    }
    else if (institutionList.place === '') {
      setError({ ...error, 'place': 'Required'})
    }
    else if (institutionList.state === '') {
      setError({ ...error, 'state': 'Required' })
    }
    else if (institutionList.district === '') {
      setError({ ...error, 'district': 'Required' })
    }
    else if(validateOrgName.status === false){
      setError({ ...error, 'organizationName':validateOrgName.message })
    }
    else if(validateEmail === false){
      setError({ ...error, 'email': 'Invalid Email'})
    }
    else if(validatePlace.status === false){
      setError({ ...error, 'place': validatePlace.message})
    }
    else {
      console.log("institustionlist------------",institutionList);
      const addNewInst = await addOrganization(institutionList);
      if (addNewInst.data) {
        seInstitutionList({
          organizationName: '',
          place: '',
          email:'',
          organizationType: '',
          district: '',
          state: '',
          longitude: "45.2559",
          latitude: "85.2369",
        })
        setOpenAlert(true);
        instRef.current.clearValue();
        stateRef.current.clearValue();
        distRef.current.clearValue();
        instNameRef.current.value ='';
        instPlaceRef.current.value = '';
        instEmailRef.current.value ='';
        onClose();
      }
      else {
        toast.error(addNewInst.response.data.message);
      }
    }
    console.log("error", error);
  }


  const [openAlert, setOpenAlert] = useState(false);


  const state = [
    { "Kerala": "Kerala" }
  ]

  const district = [
    { "Thiruvananthapuram": "Thiruvananthapuram" },
    { "Kollam": "Kollam" },
    { "Pathanamthitta": "Pathanamthitta"},
    { "Alappuzha": "Alappuzha" },
    { "Kottayam": "Kottayam" },
    { "Idukki": "Idukki" },
    { "Ernakulam": "Ernakulam" },
    { "Thrissur": "Thrissur" },
    { "Palakkad": "Palakkad" },
    { "Malappuram": "Malappuram" },
    { "Kozhikode": "Kozhikode" },
    { "Wayanad": "Wayanad" },
    { "Kannur": "Kannur" },
    { "Kasaragod": "Kasaragod"},
  ]

  return (
    <>
      <CommonAlert isOpen={openAlert} setOpen={setOpenAlert} alertMessage={"Thanks for adding new Institution "}/>
      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-7"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 bg-gray-800 bg-opacity-50 h-full ${open ? 'flex' : 'hidden'}`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-gray-100 rounded-lg shadow border border-gray-300">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900">
                Add Institution
              </h3>
              <button
                type="button"
                className="text-gray-700 bg-transparent  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-white dark:hover:text-gray-900"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="flex flex-col px-4 gap-2 mt-3">
              <p className='text-lg ml-2'>Institution Type <span className="text-red-500">*</span>   </p>
              <div className='w-full flex flex-col'>
                <CustomSelectList refv={instRef} items={institutionType} name={'organizationType'} onChange={handleChangeOfList} value={institutionList.organizationType} />
                <p className='text-red-500 ml-2 mb-[0px] text-[10px]'>{error.organizationType}</p>
              </div>
            </div>

            <div className="flex flex-col gap-2 px-4 mt-3">
              <p className='text-lg ml-2'>Institution Name <span className="text-red-500">*</span> </p>
              <TextInput refv={instNameRef}  placeholder={'Institution Name'} name={'organizationName'} label={'Institution Name'} type={'text'} onChange={hadleTextChange} />
              <p className='text-red-500 mt-[-5px] ml-2 mb-[0px] text-[10px]'>{error.organizationName}</p>
            </div>
            <div className="flex flex-col gap-2 px-4 mt-3">
              <p className='text-lg ml-2'>Institution Email <span className="text-red-500">*</span> </p>
              <TextInput refv={instEmailRef} placeholder={'Institution Email'} name={'email'} label={'Institution Email'} type={'text'} onChange={hadleTextChange} />
              <p className='text-red-500 mt-[-5px] ml-2 mb-[0px] text-[10px]'>{error.email}</p>
            </div>
            <div className="flex flex-col gap-2 px-4 mt-3">
              <p className='text-lg ml-2'>Place <span className="text-red-500">*</span></p>
              <TextInput refv={instPlaceRef} placeholder={'Place'} name={'place'} label={'Place'} type={'text'} onChange={hadleTextChange} />
              <p className='text-red-500 mt-[-5px] ml-2 mb-[0px] text-[10px]'>{error.place}</p>
            </div>

            {/* select list of state */}
            <div className="flex flex-col px-4 gap-2 mt-3">
              <p className='text-lg ml-2'>State<span className="text-red-500">*</span> </p>
              <div className='w-full flex flex-col'>
                <CustomSelectList refv={stateRef} items={state} name={'state'} onChange={handleChangeOfList} />
                <p className='text-red-500  ml-2 mb-[0px] text-[10px]'>{error.state}</p>
              </div>
            </div>

            <div className="flex flex-col px-4 gap-2 mt-3 mb-5">
              <p className='text-lg ml-2'>District <span className="text-red-500">*</span> </p>
              <div className='w-full flex flex-col'>
                <CustomSelectList refv={distRef} items={district} name={'district'} onChange={handleChangeOfList} />
                <p className='text-red-500 ml-2 mb-[0px] text-[10px]'>{error.district}</p>
              </div>
            </div>


            {/* bottom buttuns  */}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-[#3dadd3] hover:bg-[#32B1DC] focus:ring-4 focus:outline-none focus:ring-[#32B1DC] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handleAddInstitution}
              >
                Submit
              </button>
              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-200 focus:outline-none bg-[#E8453B] rounded-lg border  hover:bg-[#E8453B]  focus:z-10 focus:ring-4 focus:ring-[#E8453B]   dark:border-red-600 dark:hover:text-white"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <ToastContainer 
         position='top-right'
         autoClose={3000}    
      />
      </div>
    </>
  );
};

AddInstitutionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddInstitutionModal;
