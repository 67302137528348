import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';

import CustomSelectList from '../common/CustomSelectList';
import { getOrganization } from '../../api/get/organization';
import { addNewContactPerson } from '../../api/insert/contactPerson';
import { ValidateEmail, nameValidation, validateMobileNumber } from '../../validations/Validation';

import PhoneInputComponent from '../common/PhoneInput';
import CommonAlert from '../CommomAlert';
// Toast notification 
import { ToastContainer, toast } from 'react-toastify';


const AddContPersonModal = ({ open, onClose, isInstModalOpen,orgSelect }) => {

  const [openAlert, setOpenAlert] = useState(false);

  const [contactPerson, setContactPerson] = useState({
    organizationId:'',
    name: '',
    designation: '',
    phone: '',
    email: ''
  });

  const [error, setError] = useState({
    organizationId: '',
    name: '',
    designation: '',
    phone: '',
    email: ''
  })

  // function to handle the select list
  const handleSelectList = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);
    setError({ ...error, [name]: '' })
    setContactPerson({ ...contactPerson, [name]: value });
  }

  // handle the input of text input
  const handleTextInput = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    console.log("name", name, "value", value);
    setError({ ...error, [name]: '' })
    if (trimmedValue === '') {
      setError({ ...error, [name]: 'Cannot be empty or contain only spaces' });
    } else {
      if (name === 'email') {
        const valEmail = ValidateEmail(value);
        if (valEmail === false) {
          setError({ ...error, [name]: 'Invalid Email' })
        }
        else {
          setError({ ...error, [name]: '' })
        }
      }
      if (name === 'phone') {
        const valMobile = validateMobileNumber(value);
        if (valMobile === false) {
          setError({ ...error, [name]: 'Invalid Mobile' })
        }
        else {
          setError({ ...error, [name]: '' });
        }
      }
    }

    setContactPerson({ ...contactPerson, [name]: value });
  }

  const [organization, setOrganization] = useState([]);

  useEffect(() => {
    async function getOrg() {
      const response = await getOrganization();
      var data = [];
      response.data.organizations.forEach(element => {
        var ob = {}
        ob[element._id] = element.organizationName + " "+element.place;
        data.push(ob)
      })
      setOrganization(data);
    }
    getOrg();
  }, [isInstModalOpen])

  const nameRef = useRef(null);
  const desigRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  // add new contact person
  const addContactPerson = async () => {
    const validateName = nameValidation(contactPerson.name);
    const validateDesignation = nameValidation(contactPerson.designation);
    if (!error.email && !error.phone) {
     
      if (contactPerson.name === '') {
        setError({ ...error, 'name': 'Required' })
      }
      else if (contactPerson.designation === '') {
        setError({ ...error, 'designation': 'Required' })
      }
      else if (contactPerson.email === '') {
        setError({ ...error, 'email': 'Required' })
      }
      else if (contactPerson.phone === '') {
        setError({ ...error, 'phone': 'Required' });
      }
      else if(validateName === false){
        setError({ ...error, 'name': 'Please enter a valid name' })
      }
      else if(validateDesignation === false){
        setError({ ...error, 'designation': 'Please enter a valid designation' })
      }
      else {
        const addContactPerson = await addNewContactPerson(contactPerson,orgSelect);
        if (addContactPerson.data) {
          setOpenAlert(true)
          onClose();
          setContactPerson({
            organizationId: '',
            name: '',
            designation: '',
            phone: '',
            email: ''
          })
          nameRef.current.value = '';
          desigRef.current.value ='';
          emailRef.current.value ='';
          phoneRef.current.valu='';
        }
        else {
          toast.error(addContactPerson.response.data.message);
        }
      }
    }
  }
  return (
    <>
    
      <CommonAlert isOpen={openAlert} setOpen={setOpenAlert} alertMessage={"Thank you for adding new contact person "}/>
      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 bg-gray-800 bg-opacity-50 h-full ${open ? 'flex' : 'hidden'}`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-gray-100 rounded-lg shadow border border-gray-300">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900">
                Add Contact Person
              </h3>
              <button
                type="button"
                className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-[white] dark:hover:text-gray-900"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className='w-full p-5'>
              <div className="flex flex-col gap-1 px-4 mt-3">
                <p className='text-lg ml-2'>Select Institution<span className="text-red-500">*</span> </p>
                <div className='flex flex-col'>
                  <CustomSelectList items={organization} value={orgSelect === null?'': orgSelect} disabled={orgSelect !==null?true:false} name={'organizationId'} onChange={handleSelectList} />
                  <p className='text-red-500 ml-2 mb-[0px] text-[10px]'>{error.organizationId}</p>
                </div>
              </div>
              {/* Name of the person */}
              <div className="flex flex-col gap-1 px-4 mt-1">
                <p className='text-lg ml-2'>Name<span className="text-red-500">*</span> </p>
                <div className='flex flex-col'>
                  <TextInput placeholder={'Name'} label={'Name'} name={'name'} type={'text'} onChange={handleTextInput} refv={nameRef} />
                  <p className='text-red-500 ml-2 mb-[0px] text-[10px]'>{error.name}</p>
                </div>
              </div>
              <div className="flex flex-col gap-1 px-4 mt-1">
                <p className='text-lg ml-2'>Designation<span className="text-red-500">*</span> </p>
                <div className='flex flex-col'>
                  <TextInput placeholder={'Designation'} name={'designation'} label={'Designation'} type={'text'} onChange={handleTextInput} refv={desigRef} />
                  <p className='text-red-500 ml-2  text-[10px]'>{error.designation}</p>
                </div>
              </div>
              <div className="flex flex-col gap-1 px-4 mt-1">
                <p className='text-lg ml-2'>Email Id<span className="text-red-500">*</span> </p>
                <div className='flex flex-col'>
                  <TextInput placeholder={'Email Id'} name={'email'} label={'Email Id'} type={'email'} onChange={handleTextInput} refv={emailRef} />
                  <p className='text-red-500 ml-2 mb-[0px] text-[10px]'>{error.email}</p>
                </div>
              </div>

              <div className="flex flex-col gap-1 px-4 mt-1">
                <p className='text-lg ml-2'>Contact Number<span className="text-red-500">*</span> </p>
                <div className='flex flex-col'>
                  {/* <TextInput placeholder={'Contact Number'} name={'phone'} label={'Contact Number'} type={'text'} onChange={handleTextInput} /> */}
                  <PhoneInputComponent  onChange={handleTextInput} name={'phone'} refv={phoneRef} />
                  <p className='text-red-500 ml-2 mb-[0px] text-[10px]'>{error.phone}</p>
                </div>
              </div>
            </div>



            {/* bottom buttuns  */}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-[#3dadd3] hover:bg-[#32B1DC] focus:ring-4 focus:outline-none focus:ring-[#32B1DC] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={addContactPerson}
              >
                Submit
              </button>
              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-200 focus:outline-none bg-[#E8453B] rounded-lg border  hover:bg-[#E8453B]  focus:z-10 focus:ring-4 focus:ring-[#E8453B]   dark:border-red-600 dark:hover:text-white"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer 
         position='top-right'
         autoClose={3000}    
      />
    </>
  );
};

AddContPersonModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddContPersonModal;
