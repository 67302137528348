import React, { useEffect, useState,useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FaEye, FaTrash, FaEdit } from 'react-icons/fa';
import apiUpcomingVisits from '../api/get/UpcommingVsitsapi';
import EditVisit from '../components/instLogs/EditVisit';

import { removeVisit } from '../api/remove/visit';
import Tooltip from './common/Tooltip';

const UpcomingVisits = ({ openAddPersonModal, openInstModel, showModal, setShowModal, viewId, setViewId  , refresh}) => {



  const [visitData, setVisitData] = useState([])

  async function dataFetch() {
    var { data, loading, error } = await apiUpcomingVisits();
    // console.log("Person ", data)
    setVisitData(data);
  }

// format the the date and time
  function extractTime(dateTimeString) {
    var now = new Date(dateTimeString);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return  now ;
  }

  async function removeVisitEvent(id) {
    try {
      const response = await removeVisit(id);
      console.log(response);
      await dataFetch();
    } catch (error) {
      console.error(error);
    }

  }


  useEffect(() => {
    async function dataFetch() {
      var { data, loading, error } = await apiUpcomingVisits();
      // console.log("Person ", data)
      setVisitData(data);
    }
    dataFetch();
  }, [refresh])




  const headers = [
    { label: 'Institution', key: 'institution' },
    { label: 'Institution Type', key: 'institutionType' },
    { label: 'Date', key: 'date' },
    { label: 'Purpose', key: 'purpose' },
    { label: 'Contact Person', key: 'contactPerson' },
    { label: 'Status', key: 'status' }
  ];


  const [isModalOpen, setModalOpen] = useState(false);
  const [editModelData, setEditDataModel] = useState({

    visitId: "",
    institution: '',
    visitDateTime: '',
    purpose: '',
    contactPersonId: '',
    additionalNotes: '',
    file: '',
    visitType: 1,
    employeeId: "",
    discussionTopic: "",
    visitStatus: ""
  })


  function setFile(file) { 
    setEditDataModel({...editModelData , file })
  }



  const handleOpenModal = (data) => {
    console.log("vimal",data)
    setModalOpen(true);
    console.log(data);
    setEditDataModel({
      visitId: data._id,
      institution: data.contactPersonId.organizationId._id,
      visitDateTime: extractTime(data.date),
      purpose: data.purpose,
      contactPersonId: data.contactPersonId._id,
      additionalNotes: data.additionalNotes,
      file: data.file,
      visitType: 1,
      employeeId: data.employeeId,
      discussionTopic: data.discussionTopic,
      visitStatus: data.status
    })

  }



  const handleCloseModal = () => {
    setModalOpen(false);
  }

  function openView(id) {
    setViewId(id)
    setShowModal(true);
  }
  // sort the table data
  const flag = useRef(false);
const handleSortData = (field) => {
  flag.current = !flag.current;
    // Create a new array by spreading visitData
    const sortedData = [...visitData].sort((a, b) => {
      if(flag.current){
        if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
        if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
      }
      else{
        if(a[field].toLowerCase() > b[field].toLowerCase()) return -1;
        if(a[field].toLowerCase() < b[field].toLowerCase()) return 1;
       }
      return 0;
    });
    console.log("sorted array---", sortedData);
    setVisitData(sortedData);
  };
  

  return (
    <div className="relative w-full overflow-x-auto rounded-lg bg-gradient-to-tr from-gray-50 to-gray-100 p-6 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-blue-800">Upcoming Visits</h2>
        <CSVLink
          data={visitData}
          headers={headers}
          filename={"upcoming-visits.csv"}
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        >
          Export CSV
        </CSVLink>
      </div>
      <div className="relative overflow-x-auto  w-full">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th onClick={()=>{handleSortData('institution')}} className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">Institutions</th>
              <th onClick={()=>{handleSortData('institutionType')}} className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">Institutions Type</th>
              <th onClick={()=>{handleSortData('date')}} className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">Date</th>
              <th onClick={()=>{handleSortData('employeeName')}} className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">Employee Name</th>
              <th onClick={()=>{handleSortData('contactPerson')}} className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">Contact Person</th>
              <th onClick={()=>{handleSortData('status')}} className="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 hover:bg-gray-200 active:bg-gray-300 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
         
          <tbody className="bg-white divide-y divide-gray-200">
            {visitData.map((visit, index) => (

              <tr key={index}>

                <td className="px-6 py-4 text-wrap">{visit.institution +" , "+visit.place+" , "+ visit.district }</td>

                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`bg-${visit.institutionType === 'College' ? 'purple' : 'blue'}-200 text-${visit.institutionType === 'College' ? 'purple' : 'blue'}-800 px-2 py-1 rounded-full text-xs`}>
                    {visit.institutionType}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(visit.date).toLocaleDateString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">{visit.employeeName}</td>
                <td className="px-6 py-4 whitespace-nowrap">{visit.contactPerson}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`bg-${visit.status === 'Pending' ? 'yellow' : visit.status === 'Completed' ? 'green' : 'red'}-200 text-${visit.status === 'Pending' ? 'yellow' : visit.status === 'Completed' ? 'green' : 'red'}-800 px-2 py-1 rounded-full text-xs`}>
                    {visit.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap flex space-x-4">
                  <button className="text-blue-700 flex items-center space-x-1" onClick={() => { openView(visit._id) }}>
                    <FaEye />
                  </button>
                  {
                    visit.status !== 'Completed' && (
                      <button className="text-blue-700 flex items-center space-x-1" onClick={() => { removeVisitEvent(visit._id) }}>
                        <Tooltip text="Delete"><FaTrash /></Tooltip>
                      </button>
                    )
                  }

                  {
                    visit.status !== 'Completed' && (
                      <button className="text-blue-700 flex items-center space-x-1" 
                      onClick={() => { handleOpenModal(visit); }}>
                        <Tooltip text="Update"><FaEdit /></Tooltip>
                      </button>
                    )
                  }
                 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <EditVisit updateData={dataFetch} open={isModalOpen} visitId={editModelData.visitId} onClose={handleCloseModal} institution={editModelData.institution} visitDateTime={editModelData.visitDateTime} purpose={editModelData.purpose} employeeId={editModelData.employeeId} contactPersonId={editModelData.contactPersonId} additionalNotes={editModelData.additionalNotes} discussionTopic={editModelData.discussionTopic} visitStatus={editModelData.visitStatus}  file={editModelData.file} openAddPesonModal={openAddPersonModal} openInstModal={openInstModel}  setFile={setFile}  isUpComing={true}/>
    </div>
  );
};

export default UpcomingVisits;
