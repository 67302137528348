
const UpcommingCard = ({title,data,text,image}) => {
  return (
    <div className="w-full h-[200px] min-w-[18rem] bg-white flex flex-col px-3 py-3 rounded-2xl shadow-lg ">
      <div>
        <h2 className="text-gray-600 text-[1.2rem]">{title}</h2>
      </div>
      <div className="flex flex-col items-center">
         <div className="flex w-11/12 justify-start items-center gap-2">
         <div className="w-[80px] h-[80px] bg-gray-900 flex items-center justify-center rounded-full border-2 border-white shrink-0 p-2">
          {image}
          </div>
            <h2 className="font-bold text-[5.2rem] ">{data}</h2>
         </div>
            {/* <p className="text-gray-500 text-[.9rem]">{text}</p> */}
      </div>
    </div>
  )
}

export default UpcommingCard
