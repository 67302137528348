import axios from "axios"
import { urlLocal } from "../../url/Url";


const url = urlLocal();

export async function getVisit() {
    try {
        const response = await axios.post(`${url}/api/associates/visit/get`, {}, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        return response;
    }
    catch (error) {
        console.log(error);
    }
}


// get the visit logs of parent and its child with dynamic filtering
export const getAllVisitLogsFromDb =async(fromDate,toDate,organizationId,userId,orgTypeId)=>{
    try{
        const visitlogs = await axios.post(`${url}/api/associates/visitlogs`,{
            "fromDate":fromDate,
            "toDate":toDate,
            "organizationId":organizationId,
            "userid":userId,
            "orgTypeId":orgTypeId
        },{
            headers:{
                Authorization:localStorage.getItem("token")
            }
        });
        console.log("visitLogs in the api file",visitlogs);
        return visitlogs;
    }
    catch(error){
        console.error(error);
    }
}

// function for get parent employee and child employee data 
export const getVisitEmployeeData =async()=>{
    try{
        const emp = await axios.get(`${url}/api/associates/childemployeeList`,{
          headers:{
            Authorization:localStorage.getItem("token")
            }
        });
        return emp;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

// check the existance of visits
export const checkTheExistanceOfVisit =async(empId,contactPersonId,date)=>{
    try{
        console.log("-----",empId,contactPersonId,date);
        const vsitExist = axios.get(`${url}/api/associates/visit/check/${empId}/${contactPersonId}/${date}`,{
            headers:{
                Authorization: localStorage.getItem('token')
            }
        })
        return vsitExist;
    }
    catch(error){
        return error;
    }
}

export const getAllVisitsCount =async()=>{
    try{
        const vistCount = await axios.get(`${url}/api/associates/visitcountall`,{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return vistCount;
    }
    catch(error){
        return error;
    }
}

export const getVisitCountofOrgType=async()=>{
    try{
    const orgTypCount = await axios.get(`${url}/api/associates/visitcount-orgType`,{
        headers:{
            Authorization:localStorage.getItem('token')
        }
    });
    return orgTypCount;

    }
    catch(error){
        return error;
    }
}