import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

export const getAllOrganizationList =async()=>{
    try{
        const organizations = await axios.post(`${url}/api/admin/organization/getAll`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });

        return organizations

    }
    catch(error){
        console.error(error);
        return error;
    }
}


// get all employee details
export const getAllEmployeeDetails=async()=>{
    try{
        const emp = axios.post(`${url}/api/admin/employee/get`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return emp;
    }
    catch(error){
        console.error(error)
        return error;
    }
}

// list department
export const getAllDepartmentList =async()=>{
    try{
        const department = await axios.post(`${url}/api/admin/department/getall`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return department;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

export const fetchDesignation =async(id)=>{
    try{
        const desig = await axios.post(`${url}/api/admin/designation/getByDepartment/${id}`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return desig;
    }
    catch(error){
        return error;
    }
}

// fetch the employee based on the department
export const fetchTheEmployeeByDepartment =async(deptId)=>{
    try{
        const emploeeList = await axios.post(`${url}/api/admin/employee/${deptId}`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return emploeeList;
    }
catch(error){
    console.error(error);
    return error;
}
}