import axios from 'axios'
import { urlLocal } from '../../url/Url';

const url = urlLocal(); //local domain

// api function for adding the contact person of the organaization
const AddContactPerson = async (data) => {
    try {
        const addperson = await axios.post(`${url}/api/associates/visit/create`, data, {
            headers: {
                'Authorization': localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
        })
        return addperson;

    }
    catch (error) {
        console.error(error);
        return error;
    }
}

//  api function for adding new visits
export const AddVisit = async (visitdata) => {
    console.log("visitData submit----",visitdata);
    try {
        console.log("data" + visitdata);
        const addvisit = await axios.post(`${url}/api/associates/visit/create`, visitdata, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        });

        return addvisit;
    }
    catch (error) {
        console.error(error)
        return error
    }
}