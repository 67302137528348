import { useState } from "react";
const VisitLogsTable = ({title,content, updateFunction}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate the indexes for slicing the data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = content.slice(indexOfFirstItem, indexOfLastItem);

    // Function to handle page changes
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(content.length / itemsPerPage);

    // function for extracting date
    function extractDate(dateTimeString) {
        const date = new Date(dateTimeString);
      
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
        const day = String(date.getUTCDate()).padStart(2, '0');

        return `${day}-${month}-${year}`;
      }


      

//   function for extracting time
function extractTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return the time in the format HH:MM:SS
  return `${hours}:${minutes}:${seconds}`;
}

  return (
<div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
    <table class="w-full text-sm text-left rtl:text-right text-gray-900">
        <thead class="text-xs uppercase bg-white dark:text-gray-900">
        <tr >
           {
            title.map((data,index)=>(
               
                <th scope="col" class="px-6 py-3 font-bold text-[15px]">
                    {data}
                </th>
         
            ))
           }
            </tr>
          
        </thead>
        <tbody>
            {
                currentData.map((data,index)=>(
                    <tr class={`${index % 2 ===0 ?'bg-[#F7F6FE]':'bg-white'}`}  key={index}>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        {index+1}
                    </th>
                    <td class="px-6 py-4 text-nowrap">
                        {data.employeeId.userId.fullName}
                    </td>
                    <td class="px-6 py-4">
                        {data.contactPersonId.organizationId.organizationName}
                    </td>
                    <td class="px-6 py-4">
                        {data.purpose}
                    </td>
                    <td class="px-6 py-4">
                        {extractDate(data.visitDateTime)}
                    </td>
                    <td class="px-6 py-4">
                        {extractTime(data.visitDateTime)}
                    </td>
                    <td class="px-6 py-4">
                        <div className="bg-[#624DE3] h-[30px] w-[80px] text-[12px] text-white rounded-2xl flex justify-center items-center">
                            {data.contactPersonId.organizationId.organizationType.name}
                        </div>
                        
                    </td>
                    <td class="px-6 py-4 text-[#0D99FF]">
                        {data.contactPersonId.name}
                    </td>
                    <td class="px-6 py-4">
                        <div className={`${data.visitStatus ==='Completed'?('bg-[#d8f8e6] text-[#16864b]'):(data.visitStatus === 'Pending'?('bg-[#FEF2E5] text-[#CD6200]'):('bg-[#FBE7E8] text-[#A30D11]'))} h-[30px] w-[80px] text-[12px] rounded-2xl flex justify-center items-center`}>
                            {data.visitStatus}
                        </div>
                         
                    </td>
                    <td class="px-6 py-4">
                        {/* {data.action} */}
                        <div className="">
                            <img src={require('../../assets/edit.svg').default} alt="" />
                        </div>

                    </td>
                </tr>
                ))
            }
          
        </tbody>
    </table>
    <nav className="flex  items-center flex-column flex-wrap md:flex-row justify-between pt-4 pl-4" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900">{indexOfFirstItem + 1}-{indexOfLastItem}</span> of <span className="font-semibold text-gray-900">{content.length}</span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight  bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100  dark:bg-gray-100 dark:border-gray-700 dark:text-gray-900 dark:hover:bg-gray-400 dark:hover:text-white"
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                        <li key={pageIndex}>
                            <button
                                onClick={() => handlePageChange(pageIndex + 1)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                                    currentPage === pageIndex + 1
                                        ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-100 dark:text-gray-500"
                                        : "text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-400 dark:hover:text-white"
                                }`}
                            >
                                {pageIndex + 1}
                            </button>
                        </li>
                    ))}
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-900 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-400 dark:hover:text-white"
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </li>
                </ul>
    </nav>
</div>
)
}

export default VisitLogsTable;
