import axios from 'axios'
import { urlLocal } from '../../url/Url';

const url = urlLocal();
export const updateFollowUpAction = async (data) => {
    try {
       
        const response  = await axios.post(`${url}/api/associates/follow-up-actions/update`, data, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        });

        return response;
    }
    catch (error) {
        console.error(error)
        return error
    }
}