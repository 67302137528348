
const InstLogsCard = ({bgColor,imgbgColor,title,text}) => {
 
  return (
    <div className={`grid grid-cols-[0.7fr_1fr] w-full h-[110px] lg:h-[120px] xl:h-[140px] rounded-2xl ${bgColor} box-border overflow-hidden p-2`}>
        <div className='flex justify-center items-center'>
            <div className={`flex justify-center rounded-full h-[80px] w-[80px] sm:w-[60px] sm:h-[60px] md:w-[70px] md:h-[70px] lg:w-[60px] lg:h-[60px] xl:w-[80px] xl:h-[80px] ${imgbgColor} p-4`}>
                <img src={require('../../assets/message-outline.svg').default} alt="" className='object-contain' />
            </div>
        </div>
        <div className='flex flex-col justify-center items-start'>
            <p className='text-4xl text-white sm:text-3xl lg:text-3xl xl:text-5xl font-sans font-[500]'>
              {title}
            </p>
            <p className=' text-2xl text-white sm:text-xl lg:text-xl xl:text-3xl font-sans mt-1'>
              {text}
            </p>
        </div>
    </div>
  )
}

export default InstLogsCard
