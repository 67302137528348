import React, { useEffect, useState } from 'react'
import TextInput from '../components/common/TextInput'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import { getUserProfile } from '../api/get/profile'
import { passwordVerification, updateThePassword } from '../api/auth/Password'
// Toast notification 
import { ToastContainer, toast } from 'react-toastify';
import { updateProfile } from '../api/insert/profileUpload';
import { urlLocal } from '../url/Url'



const Profile = () => {

  const url = urlLocal();
  const [profileData, seProfileData] = useState([]);
  const [editPassOPen, seEditPassOpen] = useState(false);

  const [previousPassword, setPreviousPassword] = useState(''); //previous password

  const [changePassword, setChangePassword] = useState({
    newPassword: '',
    confirmPassword: ''
  })

  const [passwordError, setPasswordError] = useState({
    "previous": '',
    newPassword: '',
    confirmPassword: ''
  })

  useEffect(() => {
    fetchUserProfileData();
  }, [])

  // user profile data
  const fetchUserProfileData = async () => {
    const profile = await getUserProfile();
    console.log("userProfile data___", profile);
    if (profile.data) {
      seProfileData([profile.data]);
    }
  }



  //  password
  const handlePasswordCheck = (e) => {
    const { name, value } = e.target;
    setPreviousPassword(value)
  }


  // check the password
  const checkThePassword = async () => {
    const passCheck = await passwordVerification(previousPassword);
    console.log("passwordCheck", passCheck);
    if (passCheck.data) {
      if (passCheck.data.success === true) {
        setPasswordError({ ...passwordError, 'previous': '' })
      }
    }
    else {
      setPasswordError({ ...passwordError, 'previous': 'Incorrect Password' })
    }
  }

  // handle the input in newPassword and confirm password in password reset
  const handleChangeOfPasswordRest = (e) => {
    setPasswordError({ ...passwordError, newPassword:'', confirmPassword:'' })
    const { name, value } = e.target;
    if (name === 'confirmPassword' && changePassword.newPassword !== value) {
      setPasswordError({ ...passwordError, 'confirmPassword': 'Password Mismatch' })
    }
    setChangePassword({ ...changePassword, [name]: value });
  }

  // update password
  const changePasswordToNew = async () => {
    if (changePassword.newPassword !== '' && changePassword.confirmPassword !== '') {
      setPasswordError({ ...passwordError, 'newPassword': '', 'confirmPassword': '' })
      if (changePassword.newPassword === changePassword.confirmPassword) {
        const reset = await updateThePassword(previousPassword, changePassword.newPassword);
        if (reset.data) {
          if (reset.data.success === true) {
            toast.success('Success');
            seEditPassOpen(false);
          }
        }
        else {
          toast.error(reset.response.data.message);
          setPasswordError({ ...passwordError, newPassword: reset.response.data.message, confirmPassword: reset.response.data.message })
        }
      }
      else {
        setPasswordError({ ...passwordError, 'confirmPassword': 'Password mismatch' })
      }
    }
    else {
      setPasswordError({ ...passwordError, 'newPassword': 'Required', 'confirmPassword': 'Required' })
    }
  }

  // profile upload 
  const profileImageUpload = async (e) => {
    const file = e.target.files[0];
    console.log("file--", file)
    const formData =  new FormData();
    formData.append("image", file);
    const response = await updateProfile(formData);
    console.log("profile" , response);
    await fetchUserProfileData()
  }

  return (
    <>
      <Navbar title={'Profile'} />
      <Sidebar />
      <div className='sm:ml-[240px] pt-[65px] h-screen mlflex flex-col bg-[#F6F8FA] p-1 sm:p-5 pb-5 '>
        {/* <h2 className='text-3xl font-sans font-semibold'>Public Profile</h2> */}
        <hr></hr>
        <div className='flex flex-col-reverse sm:grid sm:grid-cols-[2fr_1fr] mt-10 sm:mt-0 h-auto pt-5 '>

          <div className='flex flex-col' >
            {profileData.map((data, index) => (
              <div className='flex flex-col items-center sm:p-6' >
                <div className='w-11/12 flex flex-col gap-1 mt-5'>
                  <p className='font-[500] text-gray-600'>Name</p>
                  <input
                    className="peer h-12 min-w-[200px] w-full bg-white rounded-[7px] border border-gray-300 px-3 py-2.5 font-sans text-sm font-normal text-gray-400"
                    type={'text'}
                    value={data.fullName}
                    disabled={true}
                  />
                </div>

                <div className='w-11/12 flex flex-col gap-1 mt-5'>
                  <p className='font-[500]  text-gray-600'>Department</p>
                  <input
                    className="peer h-12 min-w-[200px] w-full bg-white rounded-[7px] border border-gray-300 px-3 py-2.5 font-sans text-sm font-normal text-gray-400"
                    type={'text'}
                    value={data.employeeObject.designationId.departmentId.name}
                    disabled={true}
                  />
                </div>

                <div className='w-11/12 flex flex-col gap-1 mt-5'>
                  <p className='font-[500] text-gray-600'>Designation</p>
                  <input
                    className="peer h-12 min-w-[200px] w-full bg-white rounded-[7px] border border-gray-300 px-3 py-2.5 font-sans text-sm font-normal text-gray-400"
                    type={'text'}
                    value={data.employeeObject.designationId.name}
                    disabled={true}
                  />
                </div>

                <div className='w-11/12 flex flex-col gap-1 mt-5'>
                  <p className='font-[500] text-gray-600'>Email</p>
                  <input
                    className="peer h-12 min-w-[200px] w-full bg-white rounded-[7px] border border-gray-300 px-3 py-2.5 font-sans text-sm font-normal text-gray-400"
                    type={'text'}
                    value={data.email}
                    disabled={true}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* profile image */}
          {profileData.map((data, index) => (
           
          <div className='flex justify-center items-center p-10 sm:p-2 '> 
            <div className='w-[170px] h-[170px] md:w-[200px] md:h-[200px] lg:w-[300px] lg:h-[300px] flex relative rounded-full '>
            <div className='w-full h-full rounded-full overflow-hidden'>
              <img src={url+"/"+data.employeeObject.userId.profilePicture} alt='Img' className=' w-full h-full ' />
            </div>
              <label className='bg-white absolute bottom-2 lg:bottom-5 left-2 lg:left-8 flex gap-2 p-2 rounded-md border border-gray-300'>
                <img src={require('../assets/EditIcon.svg').default} alt='' />
                  Edit
                <input onChange={profileImageUpload} type="file" className="hidden" accept="image/*" />
              </label>
            </div>
          </div>))}
        </div>

        <hr className='w-10/12 mt-5 ml-5 sm:ml-0'></hr>

        <div className='flex flex-col sm:w-4/6 lg:w-2/6 pl-4 sm:pl-11 lg:pl-14 pt-5'>
          <div className='w-11/12 flex flex-col gap-2 mt-5'>
            <div className='flex justify-between'>
              <p className='font-[500]'>Existing Password</p>
            </div>
            <div className='flext flex-col'>
              <TextInput placeholder={'Existing Password'} label={'Existing Password'} value={previousPassword} name={'previousPassword'} onChange={handlePasswordCheck} type={'password'} />
              <p className='text-[10px] text-red-500 ml-2'>{passwordError.previous}</p>
            </div>

          </div>

          {/* {
            editPassOPen&& */}
          <div className='flex flex-col md:flex-row gap-2 mt-2'>
            <div className='flex flex-col gap-1 w-11/12'>
              <p className='font-[500]'>New Password</p>
              <div className='flext flex-col'>
                <TextInput placeholder={'Password'} name={'newPassword'} label={'Password'} type={'password'} onFocus={checkThePassword} onChange={handleChangeOfPasswordRest} />
                <p className='text-[10px] text-red-500 ml-2'>{passwordError.newPassword}</p>
              </div>
            </div>
            <div className='flex flex-col gap-1 w-11/12'>
              <p className='font-[500]'>Confirm Password</p>
              <div className='flext flex-col'>
                <TextInput placeholder={'Confirm Password'} name={'confirmPassword'} label={'Confirm Password'} type={'password'} onChange={handleChangeOfPasswordRest} />
                <p className='text-[10px] text-red-500 ml-2'>{passwordError.confirmPassword}</p>
              </div>
            </div>
            {/* <div className='flex items-end  sm:pb-2 '> 
                  <button onClick={changePasswordToNew} className='text-blue-500 border border-blue-200 p-1 rounded-md hover:bg-blue-400 hover:text-[white] active:bg-blue-500 active:text-[white] font-semibold'>Update</button>
                </div> */}

          </div>
          <div className='flex mt-3'>
            <button onClick={changePasswordToNew} className='text-blue-600 border border-blue-300 p-1 rounded-md hover:bg-blue-500 hover:text-[white] active:bg-blue-600 active:text-[white] font-semibold'>Update</button>
          </div>

          {/* } */}
          
        </div>
          <div className='py-10 px-5'>
            <p className='text-[15px] text-center text-red-500'>Password must contain at least one uppercase letter, one digit, and be at least 7 characters long</p>
          </div>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={3000}
      />
    </>

  )
}

export default Profile
