import animation from "../animation/visitComplete.json";
import AlertBox from "./AlertBox";



function NewVisitAlert({ isOpen, setOpen }) {
    return (
    <>
            <AlertBox isOpen={isOpen} setOpen={setOpen} alertMessage={"Yeah , you have added a visit"} buttonTheme={" bg-blue-600 hover:ring-blue-300 "} animation={animation}/>
    </>)
}


export default NewVisitAlert;