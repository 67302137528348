import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { urlLocal } from '../url/Url';

const MilestoneInteraction = () => {
  const [visitData, setVisitData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVisitData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found, please log in again.');
          return;
        }
        const url = urlLocal();
        const response = await axios.get(`${url}/api/associates/details/institution`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.data && response.data.data && response.data.data.visits) {
          setVisitData(response.data.data.visits);
        } else {
          setError(response.data.message || 'Failed to fetch visit data.');
        }
      } catch (error) {
        console.error('Error fetching visit data:', error);
        setError(error.message || 'Failed to fetch visit data.');
      }
    };

    fetchVisitData();
  }, []);

  const groupVisitsByDate = () => {
    const groupedVisits = {};
    if (visitData) {
      visitData.forEach(visit => {
        const visitDate = new Date(visit.visitDateTime).toDateString();
        if (!groupedVisits[visitDate]) {
          groupedVisits[visitDate] = [];
        }
        groupedVisits[visitDate].push(visit);
      });
    }
    return groupedVisits;
  };

  let lastVisitDate = null;
  let alternateSide = false;

  return (
    <div className="rounded-lg bg-gradient-to-tr from-gray-200 to-gray-50 p-6 shadow-lg">
      <h2 className="text-lg font-semibold mb-4 text-blue-800">Milestones</h2>
      <div className="space-y-2 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5 before:bg-black">
        {Object.entries(groupVisitsByDate()).map(([date, visits], index) => (
          <div key={index}>
            {visits.map((visit, idx) => {
              const visitDate = new Date(visit.visitDateTime).toDateString();
              if (visitDate !== lastVisitDate) {
                alternateSide = !alternateSide;
              }
              lastVisitDate = visitDate;

              const cardContent = (
                <div className="bg-white p-4 rounded border border-slate-200 text-slate-500 shadow ml-14 md:ml-44 w-3/4">
                  <div className="flex items-center justify-between space-x-2 mb-1">
                    <div className="font-bold text-slate-900">{visit.purpose}</div>
                    {/* <time className="font-caveat p-2 font-l text-indigo-500">{visitDate}</time> */}
                  </div>
                  <div>{visit.additionalNotes || 'No additional notes available.'}</div>
                  <div className="mt-2">
                    <h4 className="font-semibold text-gray-700">Contact Person:</h4>
                    <div className="mt-1 text-sm text-gray-600">
                      <p><strong>Name:</strong> {visit.contactPersonId.name}</p>
                      <p><strong>Designation:</strong> {visit.contactPersonId.designation}</p>
                      <p><strong>Phone:</strong> {visit.contactPersonId.phone}</p>
                      <p><strong>Email:</strong> {visit.contactPersonId.email}</p>
                    </div>
                  </div>
                  <div className="mt-2">
                    <h4 className="font-semibold text-gray-700">Employee Details:</h4>
                    <div className="mt-1 text-sm text-gray-600">
                      <p><strong>Name:</strong> {visit.employeeId.userId.fullName}</p>
                      <p><strong>Designation:</strong> {visit.employeeId.designationId.name}</p>
                    </div>
                  </div>
                </div>
              );

              return (
                <div key={visit._id} className={`relative ${alternateSide ? 'md:flex-row-reverse' : ''}`}>
                  <div className="md:flex items-center md:space-x-4 mb-3">
                    <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
                      <div className="flex items-center justify-center w-10 h-10 rounded-full bg-emerald-500 shadow md:order-1">
                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
                          <path fillRule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
                        </svg>
                      </div>
                      <time className="font-caveat font-medium text-xl text-indigo-500 md:w-28">{visitDate}</time>
                    </div>
                    <div className="text-slate-500 ml-14">
                      <span className="text-slate-900 font-bold">{visit.visitorName}</span>
                    </div>
                  </div>
                  {cardContent}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {visitData.length === 0 && (
        <div className="text-red-500 mt-4">{error || 'No visit data available.'}</div>
      )}
    </div>
  );
};

export default MilestoneInteraction;
