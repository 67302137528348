import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

// add new Contact person
export const removeFollowUpAction = async(id)=>{
    try{
        const newPerson =  await axios.post(`${url}/api/associates/follow-up-actions/remove/${id}`,{},{
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
        return newPerson;
    }
    catch(error){
        console.error(error);
        return error;
    }
}