import axios from "axios"
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function getContactPerson(orgId) {
    console.log("orgId_____________________" + orgId );
    try {
        
        const response = await axios.post(`${url}/api/associates/contactPerson/getByOrg/${orgId}`, {

        }, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        return response.data.data;
    }
    catch (error) {
        console.log(error);
    }
}