import React, { useEffect, useState } from "react";
import { getContactOrgId } from "../../api/get/getContact";

export default function ContactModal({ open, id, setOpen }) {
    const [showModal, setShowModal] = React.useState(open);
    const [data, setData] = useState([]);
    useEffect(() => {
        setShowModal(open);
    }, [open]);


    useEffect(() => {
        async function getter() {
            const data = await getContactOrgId(id);
            if (data !== undefined)
                setData(data.data.data);
            console.log("res____________", data)
        }
        getter();

    }, [id]);
    // State to manage pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of items per page

    // Logic to calculate pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Function to handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >

                        <div className="relative w-auto my-1 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Contact Details
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-1 text-blueGray-500 text-lg leading-relaxed">
                                        <div className="overflow-x-auto">
                                            <div className="p-1.5 w-full inline-block align-middle">
                                                <div className="overflow-hidden border rounded-lg">
                                                    <form class="w-full max-w-lg m-7">
                                                        <div class="flex flex-wrap -mx-3 mb-6">
                                                            <div class="w-full  px-3 mb-6 md:mb-0">
                                                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                                                    Name
                                                                </label>
                                                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" />
                                                                <p class="text-red-500 text-xs italic">Please fill out this field.</p>
                                                            </div>

                                                        </div>

                                                        <div class="flex flex-wrap -mx-3 mb-6 m-5">
                                                            <div class="w-full px-3">
                                                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                                                                    State
                                                                </label>
                                                                <div class="relative">
                                                                    <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                                                        <option>New Mexico</option>
                                                                        <option>Missouri</option>
                                                                        <option>Texas</option>
                                                                    </select>
                                                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="flex flex-wrap -mx-3 mb-2">
                                                            <div class="w-full  px-3 mb-6 md:mb-0">
                                                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                                                                    Phone
                                                                </label>
                                                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Phone Number" />
                                                            </div>
                                                            <div class="w-full  px-3 mb-6 md:mb-0">
                                                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                                                                    Email
                                                                </label>
                                                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="email" />
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div className="relative p-5 flex-auto">
                                    <p className="text-blueGray-500 text-lg leading-relaxed">
                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto">
                                                <div className="p-1.5 w-full inline-block align-middle">
                                                    <div className="overflow-hidden border rounded-lg">
                                                        <div className="overflow-x-auto">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead className="bg-gray-50">
                                                                    <tr>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                                                                            Name
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                                                                            Email
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                                                                            Phone
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                                                                            Designation
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">
                                                                            Edit
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="divide-y divide-gray-200">
                                                                    {currentItems.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.name}</td>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.email}</td>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.phone}</td>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.designation}</td>
                                                                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                                                <a className="text-green-500 hover:text-green-700" href="#">
                                                                                    Edit
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            {/* Pagination controls */}
                                                            <div className="flex justify-end mt-4">
                                                                <nav className="block">
                                                                    <ul className="flex pl-0 list-none rounded my-2">
                                                                        {/* Previous button */}
                                                                        <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 cursor-pointer hover:bg-gray-200">
                                                                            <a
                                                                                className="block"
                                                                                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
                                                                            >
                                                                                Previous
                                                                            </a>
                                                                        </li>
                                                                        {/* Page numbers */}
                                                                       
                                                                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                                                                            <li
                                                                                key={index}

                                                                                className={`relative block py-2 px-3 leading-tight border border-gray-300 ${Number(currentPage) === Number(index+1) ? 'bg-white' : 'bg-gray-300'
                                                                                    } cursor-pointer`}
                                                                            >
                                                                                <a className="block" onClick={() => paginate(index + 1)}>
                                                                                    {index + 1}
                                                                                </a>
                                                                                
                                                                            </li>
                                                                        ))}
                                                                        {/* Next button */}
                                                                        <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-l-0 cursor-pointer hover:bg-gray-200">
                                                                            <a
                                                                                className="block"
                                                                                onClick={() =>
                                                                                    setCurrentPage(
                                                                                        currentPage < Math.ceil(data.length / itemsPerPage)
                                                                                            ? currentPage + 1
                                                                                            : currentPage
                                                                                    )
                                                                                }
                                                                            >
                                                                                Next
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => { setShowModal(false); setOpen(false) }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}
